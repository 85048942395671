// eslint-disable-next-line import/no-anonymous-default-export

import React, { useState, useEffect, useRef } from 'react';
import { SelectBox, CheckBox } from 'devextreme-react';
import * as DOMPurify from 'dompurify';
import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3';
import { MapContainer, ImageOverlay, useMapEvents } from 'react-leaflet';
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  HeaderFilter,
  Selection,
  FilterPanel,
  Export
} from 'devextreme-react/data-grid';
import {
  Chart, CommonSeriesSettings, Legend, SeriesTemplate, ArgumentAxis, ValueAxis, Tick, Size
} from 'devextreme-react/chart';
import { addDays, addSeconds } from 'date-fns';
import moment from 'moment-timezone';
import { CRS, latLngBounds } from 'leaflet';
import 'leaflet/dist/leaflet.css'
import AssetsService from 'api/assets.service';
import SpacesService from 'api/spaces.service';
import { DateBox } from 'devextreme-react/date-box';
import { Button } from 'devextreme-react/button';
import './utilization.css';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

const UtilizationHeatMap = (props) => {
  const listRef = useRef();
  const dropDataGridUtilRef = useRef();
  const tenant = props.tenant;
  const [floorplan, setFloorplan] = useState("");
  const [heatmapData, setHeatMapData] = useState([]);
  const [totalDistance, setTotalDistance] = useState(0);
  const [data, setData] = useState([]);
  const [motionBarChart, setMotionBarChart] = useState([]);
  const [spaces, setSpaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('Loading...');
  const [isMotion, setIsMotion] = useState(true);
  const [isStationary, setIsStationary] = useState(true);
  const [selectedSpaceItem, setSelectedSpaceItem] = useState(null);
  const [selectedSpaceItemName, setSelectedSpaceItemName] = useState(null);
  const [height, setHeight] = useState(200);
  const [utilizationPercent, setUtilizationPercent] = useState(0);
  const [utilizationText, setUtilizationText] = useState('0 minutes');
  const [unitValue, setUnitValue] = useState('');
  const [bounds, setBounds] = useState(new latLngBounds([0, 0], [25, 35]));
  const [center, setCenter] = useState([0, 0]);
  const [showMotionStationaryErrorMsg, setShowMotionStationaryErrorMsg] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedValue, setSelectedValue] = useState('Last 24 hours');
  // const [isDisabled, setIsDisabled] = useState(true);
  const dateBoxRefStartUtil = useRef(null);
  const dateBoxRefEndUtil = useRef(null);
  // const storedZoom = JSON.parse(localStorage.getItem('zoom')) || 2.0;
  // const storedPosition = JSON.parse(localStorage.getItem('position')) || { lat: 26.140384615384615, lng: 60.94230769230769 };
  const [zoom, setZoom] = useState(2)
  const [max, setMax] = useState(0)
  // const MapEventListener = () => {
  //   const map = useMapEvents({
  //     zoomend: () => {
  //       const newZoom = map.getZoom();
  //       if (newZoom !== zoom) {
  //         // setZoom(newZoom);
  //         console.log('MapEventListener zoom:', newZoom);
  //         if (newZoom === 0) return true
  //         else localStorage.setItem('zoom', JSON.stringify(newZoom));
  //       }
  //     },
  //     moveend: () => {
  //       const newCenter = map.getCenter();
  //       if (newCenter.lat !== position.lat || newCenter.lng !== position.lng) {
  //         console.log('MapEventListener new center: ', newCenter);
  //         // setCenter([newCenter.lat, newCenter.lng]);
  //         if (newCenter.lat === -0 && newCenter.lng === 0) return true
  //         localStorage.setItem('position', JSON.stringify(newCenter));
  //       }
  //     },
  //   });
  //   return false;
  // };

  // useEffect(() => {
  //   if (mapRef.current) {
  //     mapRef.current.setView(position, zoom);
  //   }
  // }, [position, zoom]);

  useEffect(async () => {
    if(loading) {
      if (props.spaces.length>0) {
        setLoading(false);
        setTimeout(async() => {
          setHeight('100%');
            await setSpaces(props.spaces);
            await setSelectedSpaceItem(props.spaces[0].id)
            await setSelectedSpaceItemName(props.spaces[0].name)
        }, 200);
        // getDateRangeFromLocalStorage();
      }
    }
  }, [loading, props.spaces]);

  useEffect(async () => {
    setLoading(true);
  }, [props.dateRange]);

  

  function secondsToText(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    // const remainingSeconds = seconds % 60;
    let result = "";
    if (hours > 0) {
      result += hours + " hour" + (hours > 1 ? "s" : "") + " ";
    }
    if (minutes > 0) {
      result += minutes + " minute" + (minutes > 1 ? "s" : "") + " ";
    }
    if (result === '') result = `${seconds} seconds`
    return result;
  }
  const getAssetUtilizationHeatMap = async () => {
    setLoadingText('Loading...')
    setHeatMapData([])
    setFloorplan(null)
    const from = moment(localStorage.getItem(`${tenant}dateFilterFrom`)).utc().format();
    const to = moment(localStorage.getItem(`${tenant}dateFilterTo`)).utc().format();
    if (selectedSpaceItem === null) return null; // if nothing is selected from floorplans
    if (!isStationary && !isMotion) return null;
    setHeight('100%');
    await AssetsService.getAssetUtilizationHeatMap(
      props.assetId,
      selectedSpaceItem,
      from,
      to,
      isMotion,
      isStationary
    ).then(async (result) => {
      if(result.data.status===200) {
        const response = result.data.data;
        setUtilizationText(secondsToText(response.totalDurationInSeconds))
        setUnitValue(response.unit)
        setHeatMapData(response.mapData);
        setMax(response.maxValue);
        if(response.mapData.length > 0){
          setHeight('100%');
          await SpacesService.getSpaceFloorplan(selectedSpaceItem).then(async (res) => {
            let myFloorplan = URL.createObjectURL(res.data)
            await setFloorplan(myFloorplan)
          })
          // setting zoom
          if (listRef && listRef.current){
            let s = listRef.current.clientWidth
            if (listRef.current.clientHeight < s) {
                s = listRef.current.clientHeight
            }
            let z = (Math.log(s / 15.625) / Math.log(2)) - 1.5
            setZoom(z)
            // const zoomVal = localStorage.getItem('zoom');
            // console.log('postion zoomVal: ', zoomVal);
            // if (zoomVal !== undefined || zoomVal !== null){
            //   console.log('zoom val exists');
            //   setZoom(zoomVal)
            //  } 
            // else{
            //   setZoom(z)
            // }
          }
            SpacesService.getSpace(selectedSpaceItem).then((res2) => {
              let response2=res2.data
              let refSpaceBounds = response2.data.localCoordinates
              let correctedRefSpaceBounds = []
              for (let i = 0; i < refSpaceBounds.length; i++) {
                  correctedRefSpaceBounds.push([refSpaceBounds[i][1], refSpaceBounds[i][0]])
              }
              let coords = correctedRefSpaceBounds
              let xMax = 0
              let yMax = 0
              for (let i = 0; i < coords.length; i++) {
                  if (coords[i][0] > xMax) {
                      xMax = coords[i][0]
                  }
                  if (coords[i][1] > yMax) {
                      yMax = coords[i][1]
                  }
              }
              setBounds(new latLngBounds([0, 0], [xMax, yMax]))
              setCenter([0 + xMax / 2, 0 + yMax / 2])
              // const positionVal = localStorage.getItem('position');
              // console.log('postion val: ', positionVal);
              // if (positionVal !== undefined || positionVal !== null){
              //   console.log('positionVal val exists');
              //   setCenter([positionVal.lng, positionVal.lat])
              // } else{
              //   setCenter([0 + xMax / 2, 0 + yMax / 2])
              // }
            })
            const duration = moment.duration(moment(to).diff(from));
            const diff = duration.asSeconds();
            const utilizationPercentage = Math.round(((response.totalDurationInSeconds / diff) * 100));
            setUtilizationPercent(utilizationPercentage);
        }else {
          setLoadingText('No Data')
        }
      }
    });
  }

  const getAssetUtilizationChart = async () => {
    setMotionBarChart([])
    const from = moment(localStorage.getItem(`${tenant}dateFilterFrom`)).utc().format();
    const to = moment(localStorage.getItem(`${tenant}dateFilterTo`)).utc().format();
    if (selectedSpaceItem === null) return null; // if nothing is selected from floorplans
    if (!isStationary && !isMotion) return null;
    setHeight('100%');
    await AssetsService.getAssetUtilizationChart(
      props.assetId,
      selectedSpaceItem,
      from,
      to,
      isMotion,
      isStationary
    ).then(async (result) => {
      if(result.data.status===200) {
        const response = result.data.data;
            await response.timeGraphData.forEach(function(result) {
              result.start = new Date(result.start);
              result.end = new Date(result.end);
            });
            setMotionBarChart(response.timeGraphData);
        }else {
          setLoadingText('No Data')
        }
      })
    }

  const getAssetUtilizationDataGrid = async () => {
    setData([])
    setTotalDistance(0)
    const from = moment(localStorage.getItem(`${tenant}dateFilterFrom`)).utc().format();
    const to = moment(localStorage.getItem(`${tenant}dateFilterTo`)).utc().format();
    if (selectedSpaceItem === null) return null; // if nothing is selected from floorplans
    if (!isStationary && !isMotion) return null;
    setHeight('100%');
    await AssetsService.locationHistory(props.assetId, from, to, selectedSpaceItem).then((value) => {
      if (value.data.status === 200){
        setData(value.data.data.history);
        setTotalDistance(value.data.data.totalDistance);
      } else {
        setLoadingText('No Data')
      }
    })
  }

  const onFloorplanChange = async (event) => {
    await setSelectedSpaceItem(event.value);
  }


  const getFloorPlans = async() => {
    resetData()
    const from = moment(localStorage.getItem(`${tenant}dateFilterFrom`)).utc().format();
    const to = moment(localStorage.getItem(`${tenant}dateFilterTo`)).utc().format();
    if (from === "Invalid date" || to === "Invalid date") return true;
    AssetsService.locationHistory(props.assetId, from, to).then((response) => {
      if (response?.data?.data?.floorplans?.length > 0) {
        setSelectedSpaceItem(response.data.data.floorplans[0].id);
        setSelectedSpaceItemName(response.data.data.floorplans[0].name);
        setSpaces(response.data.data.floorplans);
        getAssetUtilizationHeatMap(selectedSpaceItem)
        getAssetUtilizationDataGrid(selectedSpaceItem)
        getAssetUtilizationChart(selectedSpaceItem)
      } else {
        setSelectedSpaceItem(null);
        setSelectedSpaceItemName(null);
        setSpaces([]);
        setLoadingText("No Data")
        setMotionBarChart([])
        setData([])
        setHeatMapData([])
        setTotalDistance(0)
        setFloorplan(null)
      }
    })
  };

  const OnMotionChange = async(motion) => {
    if(motion === 'isMotion') {
      await setIsMotion(!isMotion);
    } else if(motion === 'isStationary') {
      await setIsStationary(!isStationary);
    }
  };

  useEffect(() => {
    // Fetch data when the component mounts
    getFloorPlans();
  }, []);

  // useEffect(async () => {
  //   getFloorPlans();
  // }, [props.dateRange]);

  async function resetData(){
    setUtilizationPercent(0)
    setHeatMapData([])
    setMotionBarChart([])
    setSpaces([])
    setData([])
    // setIsDisabled(true)
    setFloorplan("")
    // setTotalMinutes(0)
    setUtilizationText("0 minutes")
    setTotalDistance(0)
  }

  const handleValueChange = async (value) => {
    await resetData();
    setSelectedValue(value);
    if (value === 'Custom') {
      // setIsDisabled(false)
      localStorage.setItem(`${tenant}dateFilterText`, value);
    } else {
      setStartDate(null);
      setEndDate(null);
      let start, end;
      switch (value) {
        case 'Last 24 hours':
          start = moment().subtract(24, 'hours').format();
          end = moment().format();
          break;
        case 'Today':
          start = moment().startOf('day').format();
          end = moment().endOf('day').format();
          break;
        case 'Last 5 min':
          start = moment().subtract(5, 'minutes').format();
          end = moment().format();
          break;
        case 'Last 20 min':
          start = moment().subtract(20, 'minutes').format();
          end = moment().format();
          break;
        case 'Last 1 hour':
          start = moment().subtract(1, 'hour').format();
          end = moment().format();
          break;
        case 'Last 4 hours':
          start = moment().subtract(4, 'hours').format();
          end = moment().format();
          break;
        case 'Last 12 hours':
          start = moment().subtract(12, 'hours').format();
          end = moment().format();
          // if (moment(end).diff(start, 'hours') <= 12) {
          //   end = moment().endOf('hour').format(); // Set end to end of current hour
          // }
          break;
        case 'Yesterday':
          start = moment().subtract(1, 'day').startOf('day').format();
          end = moment().subtract(1, 'day').endOf('day').format();
          break;
        case 'This Week':
          start = moment().startOf('week').format();
          end = moment().endOf('week').format();
          break;
        case 'Last Week':
          start = moment().subtract(1, 'weeks').startOf('week').format();
          end = moment().subtract(1, 'weeks').endOf('week').format();
          break;
        case 'This Month':
          start = moment().startOf('month').format();
          end = moment().endOf('month').format();
          break;
        case 'Last Month':
          start = moment().subtract(1, 'month').startOf('month').format();
          end = moment().subtract(1, 'month').endOf('month').format();
          break;
        // case 'Last 1 day':
        //   start = moment().subtract(1, 'day').format();
        //   end = moment().format();
        //   break;
        case 'Last 7 days':
          start = moment().subtract(7, 'days').format();
          end = moment().format();
          break;
        default:
          break;
      }
      console.log('start: ', start, ',end: ', end);
      setStartDate(start);
      setEndDate(end);
      localStorage.setItem(tenant + "dateFilterText", `${value}`)
    }
  };

  function onMounted(num) {
    if (document.querySelector('.leaflet-draw-section .leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-top') !== null) {
      {
        document.querySelector('.leaflet-draw-section .leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-top').style.setProperty('--thumb-width', 'hidden');
        document.querySelector('.leaflet-draw.leaflet-control').style.setProperty('--display', 'flex');
        document.querySelector('.leaflet-draw.leaflet-control').style.setProperty('--margin', '0px');
      }
    }
  }

  // function getUnit(maxSeconds) {
  //   if (maxSeconds <= 4 * 60 * 60) { // 4 hours
  //     return 'minutes';
  //   } else if (maxSeconds <= 48 * 60 * 60) { // 48 hours
  //     return 'hours';
  //   } else {
  //     return 'days';
  //   }
  // }

  function delayFn() {
      setTimeout(function () { onMounted(1) }, 1);
  }

  const handleStartDateUtil = () => {
    dateBoxRefStartUtil.current.instance.open();
    setSelectedValue("Custom")
  }

  const handleEndDateUtil = () => {
    dateBoxRefEndUtil.current.instance.open();
    setSelectedValue("Custom")
  }

  const handleStartDateChange = async (value) => {
    await setStartDate(value);
    if (value && endDate && moment(value).isAfter(endDate)) {
      await setEndDate(moment(value).add(1, 'days').toDate());
      localStorage.setItem(`${tenant}dateFilterTo`, moment(value).add(1, 'days').toDate())
    }
    localStorage.setItem(`${tenant}dateFilterFrom`, moment(value).format())

    if (endDate) {
      localStorage.setItem(`${tenant}dateFilterTo`, moment(endDate).format());
    }
    getFloorPlans()
  };

  const handleEndDateChange = async (value) => {
    await setEndDate(value);
    if (value && startDate && moment(value).isBefore(startDate)) {
      await setStartDate(moment(value).subtract(1, 'days').toDate());
      localStorage.setItem(`${tenant}dateFilterFrom`, moment(value).subtract(1, 'days').toDate())
    }
    localStorage.setItem(`${tenant}dateFilterTo`, moment(value).format())
    if (startDate) {
      localStorage.setItem(`${tenant}dateFilterFrom`, moment(startDate).format());
    }
    getFloorPlans()
  };

  useEffect(async () => {
    if (!isStationary && !isMotion){
      await setShowMotionStationaryErrorMsg(true)
    } else {
      getAssetUtilizationHeatMap()
      await setShowMotionStationaryErrorMsg(false)
    }
  }, [isMotion, isStationary]);

  useEffect(async () => {
      getFloorPlans()
  }, [selectedSpaceItem]);

  useEffect(() => {
    if (startDate && endDate) {
      getFloorPlans();
    }
}, [startDate, endDate]);

  useEffect(() => {
    const periodVal = localStorage.getItem(`${tenant}dateFilterText`);
    if (periodVal !== null && periodVal !== 'null') {
      console.log('period val: ', periodVal);
      if (periodVal === 'Custom') {
          const startDate = moment(localStorage.getItem(`${tenant}dateFilterFrom`));
          const endDate = moment(localStorage.getItem(`${tenant}dateFilterTo`));
          setStartDate(startDate);
          setEndDate(endDate);
      }
      // setSelectedValue(periodVal);
      handleValueChange(periodVal);
    } else {
      handleValueChange('Last 24 hours')
    }
    getFloorPlans()
  }, [props.selectedTabIndex]);


  const customizeSeries = (deviceMotion) => {
    if(deviceMotion === 'Motion') {
      return { color: '#FFC001'};
    }
    return { color: '#CCCCCC'};
  };

  const customizeTextLegend = (item) => {
    return item.seriesName === 'noMotion' ? 'No Motion' : item.seriesName;
  }

  function exportGridUtilization(e) {
    const workbook = new Workbook(); 
    const worksheet = workbook.addWorksheet("Utilization");
    const startDateGrid = moment(localStorage.getItem(`${tenant}dateFilterFrom`));
    const endDateGrid = moment(localStorage.getItem(`${tenant}dateFilterTo`));
    const startTime = new Date(startDateGrid).toLocaleString('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
    const endTime = new Date(endDateGrid).toLocaleString('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
    worksheet.insertRow(1, ['','','','Asset Name', props.assetName]);
    worksheet.insertRow(2, ['','','','Date Range', `${startTime} - ${endTime}`]);
    worksheet.insertRow(3, ['','','','Total Distance', totalDistance]);
    worksheet.insertRow(4, ['','','','Total Motion Time', utilizationText]);
    worksheet.insertRow(5, ['','','','Floorplan', selectedSpaceItemName]);
    worksheet.columns = [
          { width: 5 },
          { width: 30 },
          { width: 25 },
          { width: 15 },
          { width: 25 },
          { width: 40 },
        ];
    exportDataGrid({
          component: dropDataGridUtilRef.current.instance,
          worksheet,
          keepColumnWidths: false,
          topLeftCell: { row: 7, column: 1 },
          customizeCell: ({ gridCell, excelCell }) => {
            console.log('gridcell', gridCell);
            console.log('excelCell', excelCell);
            if (gridCell.rowType === 'data') {
              if (gridCell.column.dataField === 'time') {
                excelCell.value = new Date(excelCell.value).toLocaleString('en-US', {
                  weekday: 'short',
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true
                });
              }
            }
          },
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Asset-Utilization-${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`);
          });
        })
    e.cancel = true; 
  }

  return (
    <div className='utilizationContainer' style={{display: 'block', position: 'relative', width: '100%', minHeight: height}}>
      <div className={'btnContainer'}>
      <div className='dateBtnUtilization'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SelectBox
              style={{ marginRight: '10px', width: '20%' }}
              dataSource={['Today', 'Last 5 min', 'Last 20 min', 'Last 1 hour', 'Last 4 hours', 'Last 12 hours', 'Last 24 hours', 'Yesterday', 'This Week', 'Last Week', 'This Month', 'Last Month', 'Last 7 days', 'Custom']}
              value={selectedValue}
              onValueChanged={(e) => handleValueChange(e.value)}
              labelMode="static"
              label="Period"
              stylingMode="outlined"
            />
            <div onClick={() => handleStartDateUtil()}>
              <DateBox
                ref={dateBoxRefStartUtil}
                style={{ marginRight: '10px'}}
                type="datetime"
                value={startDate}
                onValueChanged={async (e) => await handleStartDateChange(e.value)}
                placeholder="Start Date"
                labelMode="static"
                label="Start Date"
                stylingMode="outlined"
                displayFormat="yyyy-MM-dd HH:mm"
                valueFormat="yyyy-MM-dd HH:mm"
              />
            </div>
            <div onClick={() => handleEndDateUtil()}>
              <DateBox
                ref={dateBoxRefEndUtil}
                style={{ marginRight: '10px'}}
                type="datetime"
                value={endDate}
                onValueChanged={(e) => handleEndDateChange(e.value)}
                placeholder="End Date"
                labelMode="static"
                label="End Date"
                stylingMode="outlined"
                displayFormat="yyyy-MM-dd HH:mm"
                valueFormat="yyyy-MM-dd HH:mm"
              />
            </div>
          <SelectBox
            style={{ marginRight: '10px'}}
            labelMode="static"
            label="Floorplan"
            items={spaces}
            stylingMode="outlined"
            value={selectedSpaceItem}
            onValueChanged={onFloorplanChange}
            valueExpr="id"
            displayExpr="name"
          >
          </SelectBox>

          <div className='uploadBtnMechanicalReport'>
            <button id="generatePdfButton" alt="Export Data" title="Export Data" type="button" onClick={exportGridUtilization}>
              <img src="../icons/excelNew.svg" alt="Export Data" style={{height: "24px", width:"24px"}} />
            </button>
          </div>


          
          {/* <Button
            width={"160px"}
            height={"88%"}
            style={{marginTop: "5px",  marginRight: '10px'}}
            type="normal"
            stylingMode="outlined"
            onClick={clearSelection}
            text="Clear Filters"
            disabled={isDisabled}
          /> */}
        </div>
        </div>
      </div>
      <div style={{margin: 10}}>
        <div className="utilizationTextCheckbox">
          <p>Total utilization in the selected date range: <b>{isMotion ? totalDistance.toLocaleString() : 0}m</b> during <b>{isMotion ?  utilizationText : 'O minutes'}</b>(<b>{isMotion ? utilizationPercent : 0}% utilization</b>)</p>
        </div>
        <div style={{width: "70%", marginTop: "30px", marginBottom: "30px"}}>
        {motionBarChart.length ?
        <Chart animation={{"enabled":false}} dataSource={motionBarChart} barGroupPadding={0.2} rotated={true}>
          <Size
            width={'100%'}
            height={150}
          />
          <ArgumentAxis categories={['Device Motion']} label={{
              customizeText: (arg) => {
                return ''
              }
            }}
          >
            <Tick visible={false} />
          </ArgumentAxis>
          <ValueAxis
            label={{
              customizeText: (arg) => {
                return moment(arg.value).format("YYYY-MM-DD HH:mm")
              },
            }}
          />
          <CommonSeriesSettings
            type="rangeBar"
            argumentField="activity"
            rangeValue1Field="start"
            rangeValue2Field="end"
            barOverlapGroup="activity"
          >
          </CommonSeriesSettings>
          <Legend verticalAlignment="center" horizontalAlignment="left" customizeText={customizeTextLegend}/>
          <SeriesTemplate nameField="action" customizeSeries={customizeSeries} />
        </Chart> : ""}
        {/* {motionBarChart.length ?
          <div style={{ position: 'absolute', top: '13%', right: '0', transform: 'translateY(50%)', textAlign: 'right', paddingRight: '255px' }}>
              <p>{utilizationPercent}% utilization</p>
          </div> : ""} */}
        </div>
        <div style={{ height: '100%', width: '100%' }} ref={listRef}>
        <MapContainer
          id="map"
          center={center}
          style={{ height: '400px', width: '100%' }}
          zoom={12}
          crs={CRS.Simple}
          whenReady={() => {
            delayFn()
          }}
          min-zoom={3.2}
          maxZoom={7}
          zoomSnap={0}
        >
          {/* <ChangeView center={center} zoom={3} />  */}
          {heatmapData.length===0 && (
            <div className='noDataHeatMap'>
              <p>{loadingText}</p>
            </div>
          )}
          {floorplan!=null && <ImageOverlay
            url={floorplan}
            bounds={bounds}
            style={{ height: '350px', width: '100px' }}
            opacity={1}
            zIndex={10}
          />}
          <HeatmapLayer
            fitBoundsOnLoad
            fitBoundsOnUpdate
            points={heatmapData}
            key={Math.random() + Math.random()}
            longitudeExtractor={(point) => point.lng}
            latitudeExtractor={(point) => point.lat}
            intensityExtractor={(point) => parseFloat(point.value)}
            max={max}
            // radius={30}
            {...heatmapData}
          />
        </MapContainer>
        {heatmapData.length > 0 && (
          <div className="gradientScaleWithNumber">
            <div className="gradientScaleParent">
              <div className="gradientScale"></div>
            </div>
            <p className="leftgradiantval">0</p>
            <p className="activityMinutes">Time [{unitValue}]</p>
            <p className="rightgradiantval">{max}</p>
          </div>
        )}
        <div className="utilizationCheckbox">
            <CheckBox
              value={isMotion}
              text="Motion time"
              hint="Motion"
              iconSize="15"
              onValueChanged={()=>OnMotionChange('isMotion')}
            />
            <div style={{paddingTop: "10px"}}></div>
            <CheckBox
              value={isStationary}
              text="No motion time"
              hint="Stationary"
              iconSize="15"
              onValueChanged={()=>OnMotionChange('isStationary')}
            />
            <div style={{display: showMotionStationaryErrorMsg ? "block": "none", color: "#C5260C"}}>
              <p>Check either or both boxes to see Utilization data</p>
            </div>
          </div>
        <div style={{paddingTop:'50px'}}>
        <DataGrid
        // onExporting={exportGridUtilization}
        height={'calc(100vh - 329px)'}
        dataSource={data}
        ref={dropDataGridUtilRef}
        columnAutoWidth={true}
        allowColumnResizing={true}
        showBorders={true}
        noDataText={loadingText}
        keyExpr="id"
      >
        {/* <Export
          enabled={true}
        /> */}
        <Selection
          mode="multiple"
          showCheckBoxesMode="none"
        />
        <FilterPanel visible={true} texts={{createFilter: '', clearFilter:'Clear Filter', filterEnabledHint: ''}}/>
        <Paging enabled={false} />
        <FilterRow visible="true" apply-filter="true" />
        <HeaderFilter visible="showHeaderFilter" />
        <Column
          allowSorting={true}
          dataField={DOMPurify.sanitize('time')}
          caption={'Date'}
          dataType="datetime"
          format="yyyy-MM-dd HH:mm"
          allowFiltering={false}
          defaultSortIndex={1}
          defaultSortOrder="desc"
          // width={"40%"}
          // fixed={true}
          hidingPriority={8}
        >
        </Column>
        <Column
          dataField={DOMPurify.sanitize('space.name')}
          caption={'Space'}
          dataType="string"
          alignment={"left"}
          // fixed={true}
        >
        </Column>
        <Column
          dataField={DOMPurify.sanitize('motion')}
          caption={'Event'}
          dataType="string"
          alignment={"left"}
          // fixed={true}
        >
        </Column>
        <Column
          dataField={DOMPurify.sanitize('duration')}
          caption={'Duration'}
          dataType="string"
          alignment={"left"}
          // fixed={true}
        >
        </Column>
        <Column
          dataField={DOMPurify.sanitize('distance')}
          caption={'Distance [m]'}
          dataType="number"
          alignment={"left"}
          // fixed={true}
        >
        </Column>
      </DataGrid>
      </div>
        </div>
        
        
      </div>
    </div>
  );
}
export default UtilizationHeatMap;
