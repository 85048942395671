import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../spaces.css'
import './upload.css'
import Toast from 'components/Toast';
import SpacesService from '../../../api/spaces.service';
import { useRef, useMemo } from "react";
import { MapContainer, Marker, ImageOverlay} from 'react-leaflet'
import { latLngBounds } from 'leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import {
    Tooltip,
    Polyline,
} from "react-leaflet";
import { CRS } from 'leaflet';
import "leaflet-draw/dist/leaflet.draw-src.css";
import 'leaflet/dist/leaflet.css'
import transformRotate from '@turf/transform-rotate';
import rhumbBearing from '@turf/rhumb-bearing';
import transformTranslate from '@turf/transform-translate';
import { useHistory } from "react-router";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})


const Upload = (props) => {
    const [center, setCenter] = useState([0, 0])
    const [bounds, setBounds] = useState(new latLngBounds([0, 0], [25, 35]));
    const markerRef = useRef(null)
    const markerRef2 = useRef(null)
    const [p1] = useState({ lng: 10, lat: 20 })
    const [p2] = useState({ lng: 40, lat: 20 })
    const [imgWidth, setImgWidth] = useState(0)
    const [imgHeight, setImgHeight] = useState(0)
    const [marker1, setMarker1] = useState({ y: 0, x: 0 })
    const [marker2, setMarker2] = useState({ y: 1, x: 1 })
    const [browseClass, setBrowseClass] = useState("classVisible")
    const [showGeo, setShowGeo] = useState(false)
    const [widthClass, setWidthClass] = useState("classInvisible")
    const [heightClass, setHeightClass] = useState("classInvisible")
    const [p1Class, setP1Class] = useState("classInvisible")
    const [p2Class] = useState("classInvisible")
    const [polyline, setPolyline] = useState([[0, 0], [0, 0]])
    const [showLine, setShowline] = useState("x")
    const [bHeight, setBHeight] = useState()
    const [bWidth, setBWidth] = useState()
    const [m1Lat, setM1Lat] = useState(null)
    const [m1Lng, setM1Lng] = useState(null)
    const [m2Lat, setM2Lat] = useState(null)
    const [m2Lng, setM2Lng] = useState(null)
    const [bwFactor, setBwFactor] = useState()
    const [bhFactor, setBhFactor] = useState()
    const [geoCoordinates, setGeoCoordinates] = useState(null)
    const [title, setTitle] = useState("Upload Floorplan")
    const [descriptionText, setDescriptionText] = useState("")
    const [showDelete, setShowDelete] = useState(props.history.location.state.showDelete)
    const [selectedImage, setSelectedImage] = useState(null);
    const [boundsX, setBoundsX] = useState(null)
    const [boundsY, setBoundsY] = useState(null)
    const [uploadBtnVisible, setUploadBtnVisible] = useState("classInvisible")
    const [toastConfig, setToastConfig] = React.useState({
        isVisible: false,
        type: 'info',
        message: '',
        displayTime: 5000,
    });

    const history = useHistory();

    const spaceId = props.history.location.state.spaceId

    const handleSubmit = (event) => {
        event.preventDefault()
        const formData = new FormData();
        const token = localStorage.getItem('token');
        formData.append("floorplan", selectedImage);
        formData.append("xMax", bWidth);
        formData.append("yMax", bHeight);
        formData.append("spaceId", spaceId);
        const formData2 = new FormData();
        formData2.append("spaceId", spaceId);
        formData2.append("geoCoordinates", geoCoordinates);

        if (boundsX === null || boundsY === null) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                status: 500,
                message: "The Length (X) and Width (Y) cannot be empty and must be numbers",
            });
        } else if (isNaN(boundsX) || isNaN(boundsY) || boundsX <= 0 || boundsY <= 0) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                status: 500,
                message: "The Length (X) and Width (Y) must be numbers greater than 0",
            });
        } else if (geoCoordinates === null && showGeo) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                status: 500,
                message: "Incorrect Geo Coordinates",
            });
        } else if (selectedImage === null) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                status: 500,
                message: "No floorplan image provided",
            });
        } else {
            
            const response = axios({
                method: "post",
                url: "/api/space/floorplan/upload",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    'x-access-token': token
                },
            }).then(response => {
                if (response.data.message === "Success") {
                    setToastConfig({
                        ...toastConfig,
                        isVisible: true,
                        type: 'success',
                        status: 200,
                        message: "Upload successful",
                    });
                    setTimeout(() => {
                        setSelectedImage(null)
                        history.push({
                            pathname: `/config/space`
                        })    
                    }, 2000);
            }
        }
            ).catch(error => {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    type: 'error',
                    status: 500,
                    message: error,
                });
            })
            if (showGeo) {
                SpacesService.updateGeoCoordinates(spaceId, geoCoordinates).then((response) => {
                    if (response.status === 200) {
                        setSelectedImage(null)
                        setToastConfig({
                            isVisible: true,
                            type: 'success',
                            status: 200,
                            message: "Upload successful",
                        });
                    }
                }).catch(error => {
                    setToastConfig({
                        isVisible: true,
                        type: 'error',
                        status: 500,
                        message: error,
                    });
                })
            }
        }

    }

    function handleCancel(e){
        history.push({pathname: '/config/space'})
    }

    function handleDelete(e) {
        SpacesService.deleteFloorplan(spaceId).then((res) => {
            setSelectedImage(null)
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'success',
                status: 200,
                message: "Delete successful",
            });
        })
        history.push({pathname: '/config/space'})
    }

    useEffect(() => {
        setSelectedImage(null)
    }, [spaceId]);

    useEffect(() => {
        //console.log required
    }, [polyline]);

    useEffect(() => {
        //console.log required
    }, [imgWidth, imgHeight]);

    useEffect(() => {
        console.log('showLine', showLine);
        //console.log required
    }, [showLine]);

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                let newPosition = { y: marker.getLatLng().lat, x: marker.getLatLng().lng }
                setMarker1(newPosition)
                if (showLine === 'x') {

                    let c1 = [marker.getLatLng().lat, 0]
                    let c2 = [marker.getLatLng().lat, imgWidth]
                    setPolyline([c1, c2])
                }
                if (showLine === 'y') {
                    let c1 = [0, marker.getLatLng().lng]
                    let c2 = [imgHeight, marker.getLatLng().lng]
                    setPolyline([c1, c2])
                }


            },
        }),
        [imgWidth, imgHeight, showLine],
    )

    const eventHandlers2 = useMemo(
        () => ({
            dragend() {
                const marker = markerRef2.current
                let newPosition = { y: marker.getLatLng().lat, x: marker.getLatLng().lng }
                setMarker2(newPosition)
            },
        }),
        [],
    )

    useEffect(async() => {
        if (selectedImage !== null) {
            let myFloorplan = URL.createObjectURL(selectedImage)
            await getMeta(myFloorplan)
            setWidthClass("classVisible")
            setTitle("Upload Floorplan  |  Define Width")
            setDescriptionText("Pick two places on the map on a horizontal line and with a known width. Then Place Marker 1 at one end and Marker 2 at the other end. Caution: the distance between the Markers must be at least 50% of the floorplan width.")
            const marker = markerRef.current
            if (marker) {
                const newPosition = { y: marker.getLatLng().lat, x: marker.getLatLng().lng }
                let c1 = [marker.getLatLng().lat, 0]
                let c2 = [marker.getLatLng().lat, imgWidth]
                setPolyline([c1, c2])
            }
        }

    }, [selectedImage, imgWidth]);

    function getMeta(url) {
        var img = new Image();

        let myHeight
        let myWidth
        img.onload = function () {
            myHeight = this.height
            myWidth = this.width
            if (this.height > 300) {
                let myScaleFactor = this.height / 300
                myHeight = this.height / myScaleFactor
                myWidth = this.width / myScaleFactor
                setImgWidth(myWidth)
                setImgHeight(myHeight)

            } else {
                setImgWidth(myWidth)
                setImgHeight(myHeight)
            }
            setBounds(new latLngBounds([0, 0], [myHeight, myWidth]))
            setCenter([myHeight / 2, myWidth / 2])
        };
        img.src = url;
    }

    function getGeoCorners(x1, y1, x2, y2, lng1, lat1, lng2, lat2) {

        if (x1 > x2) {
            let sx1 = x1
            let sy1 = y1
            let sx2 = x2
            let sy2 = y2
            let slng1 = lng1
            let slat1 = lat1
            let slng2 = lng2
            let slat2 = lat2
            x1 = sx2
            y1 = sy2
            x2 = sx1
            y2 = sy1
            lng1 = slng2
            lat1 = slat2
            lng2 = slng1
            lat2 = slat1
        }


        let bearing
        let angle
        let offsetAngle
        let pointGeoJSON
        let pointGeoJSON2
        let translatedCoordsX // cx1
        let translatedCoords // cy1
        let c2
        let c3
        let c4
        let rc2
        let rc3
        let rc4
        let options
        let rotatedCoords
        let xSame
        let ySame
        let x0
        let y0

        
        bearing = rhumbBearing([lng1, lat1], [lng2, lat2]);
        angle = Math.atan((y2 - y1) / (x2 - x1)) * (180 / Math.PI)

        pointGeoJSON = {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [lng1, lat1]
            },
            "properties": {
                "name": "test"
            }
        }
        pointGeoJSON2 = {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [lng2, lat2]
            },
            "properties": {
                "name": "test"
            }
        }

        angle = 90 - angle
        offsetAngle = bearing - angle

        xSame = ((x2 - x1 < 0 && lng2 - lng1 < 0) || (x2 - x1 > 0 && lng2 - lng1 > 0))
        ySame = ((y2 - y1 < 0 && lat2 - lat1 < 0) || (y2 - y1 > 0 && lat2 - lat1 > 0))


        x0 = ((x2 - x1 === 0) || (lng2 - lng1 === 0))
        y0 = ((y2 - y1 === 0) || (lat2 - lat1 === 0))

        if (x0) {
            if (ySame) {
                x2 = x2 + 0.0001
                lng2 = transformTranslate(pointGeoJSON2, 0.0001 / 1000, 90).geometry.coordinates[0]
            } else {
                x1 = x1 + 0.0001
                lng1 = transformTranslate(pointGeoJSON2, 0.0001 / 1000, 90).geometry.coordinates[0]
            }

        }
        if (y0) {
            if (xSame) {
                y2 = y2 + 0.0001
                lat2 = transformTranslate(pointGeoJSON2, 0.0001 / 1000, 0).geometry.coordinates[0]

            } else {
                y1 = y1 + 0.0001
                lat1 = transformTranslate(pointGeoJSON2, 0.0001 / 1000, 0).geometry.coordinates[0]

            }

        }

        xSame = ((x2 - x1 < 0 && lng2 - lng1 < 0) || (x2 - x1 > 0 && lng2 - lng1 > 0))
        ySame = ((y2 - y1 < 0 && lat2 - lat1 < 0) || (y2 - y1 > 0 && lat2 - lat1 > 0))


        let conditionA = (((lat1 < lat2) && xSame) || ((lat1 > lat2) && !xSame))
        let conditionB = (((lat1 > lat2) && xSame) || ((lat1 < lat2) && !xSame))


        // if (y1 < y2) {
        if (conditionA) {
            angle = angle + 180
            if (xSame && ySame) {
                translatedCoordsX = transformTranslate(pointGeoJSON, x1 / 1000, 270)
                translatedCoords = transformTranslate(translatedCoordsX, y1 / 1000, 180)

                offsetAngle = -(bearing + angle - 180)
            }
            else if (xSame && !ySame) {
                translatedCoordsX = transformTranslate(pointGeoJSON, x1 / 1000, 270)
                translatedCoords = transformTranslate(translatedCoordsX, y1 / 1000, 180)
            }
            else if (!xSame && ySame) { // cant get here
                translatedCoordsX = transformTranslate(pointGeoJSON, x1 / 1000, 270)
                translatedCoords = transformTranslate(translatedCoordsX, y1 / 1000, 180)

            }
            else if (!xSame && !ySame) {
                translatedCoordsX = transformTranslate(pointGeoJSON, x1 / 1000, 90)
                translatedCoords = transformTranslate(translatedCoordsX, y1 / 1000, 0)
                offsetAngle = 360 - (bearing + angle)

            }

        }
        if (conditionB) {
            if (xSame && ySame) {
                translatedCoordsX = transformTranslate(pointGeoJSON, x1 / 1000, 270)
                translatedCoords = transformTranslate(translatedCoordsX, y1 / 1000, 180)
                offsetAngle = bearing - angle
            }
            else if (xSame && !ySame) {
                translatedCoordsX = transformTranslate(pointGeoJSON, x1 / 1000, 270)
                translatedCoords = transformTranslate(translatedCoordsX, y1 / 1000, 180)
                offsetAngle = bearing - angle
            }
            else if (!xSame && ySame) {
                translatedCoordsX = transformTranslate(pointGeoJSON, x1 / 1000, 90)
                translatedCoords = transformTranslate(translatedCoordsX, y1 / 1000, 180)

            }
            else if (!xSame && !ySame) {
                translatedCoordsX = transformTranslate(pointGeoJSON, x1 / 1000, 90)
                translatedCoords = transformTranslate(translatedCoordsX, y1 / 1000, 0)
                // offsetAngle = offsetAngle - 180
                offsetAngle = -(bearing + angle) + 180

            }

        }
        c2 = transformTranslate(translatedCoords, bHeight / 1000, 0)
        c3 = transformTranslate(c2, bWidth / 1000, 90)
        c4 = transformTranslate(c3, bHeight / 1000, 180)

        options = { pivot: [lng1, lat1] };
        rotatedCoords = transformRotate(translatedCoords, offsetAngle, options)
        rc2 = transformRotate(c2, offsetAngle, options)
        rc3 = transformRotate(c3, offsetAngle, options)
        rc4 = transformRotate(c4, offsetAngle, options)

        setGeoCoordinates([
            [rotatedCoords.geometry.coordinates[1], rotatedCoords.geometry.coordinates[0]],
            [rc3.geometry.coordinates[1], rc3.geometry.coordinates[0]],
            [rc2.geometry.coordinates[1], rc2.geometry.coordinates[0]],
            [rc4.geometry.coordinates[1], rc4.geometry.coordinates[0]]
        ])
    }



    function showLeaflet() {
        if (selectedImage !== null) {
            let myFloorplan = URL.createObjectURL(selectedImage)
            return (
                <div className='upload-leaflet'  >
                    <MapContainer
                        id="map"
                        center={center}
                        style={{ height: '100%', width: '100%' }}
                        zoom={-5}
                        min-zoom={-5}
                        crs={CRS.Simple}
                    >
                        <ImageOverlay
                            url={myFloorplan}
                            bounds={bounds}
                            opacity={1}
                            zIndex={10}
                        />
                        <Polyline positions={polyline}>
                        </Polyline>
                        <Marker id={1} key={1} position={p1} draggable={true} eventHandlers={eventHandlers} ref={markerRef}>
                            <Tooltip direction="top" offset={[-15, -12]} opacity={1} permanent>Marker 1</Tooltip>
                        </Marker>
                        <Marker id={2} key={2} position={p2} draggable={true} eventHandlers={eventHandlers2} ref={markerRef2}>
                            <Tooltip direction="top" offset={[-15, -12]} opacity={1} permanent>Marker 2</Tooltip>
                        </Marker>

                    </MapContainer>
                </div>
            )
        } else {
            return (<></>)
        }
    }

    function heightClick() {
        setShowDelete(false)
        if (boundsY === null || isNaN(boundsY)) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                status: 500,
                message: "No height provided",
            });

        } else {
            let floorplanHeight = Math.abs(marker1.y - marker2.y)
            if (floorplanHeight < imgHeight / 2) {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    type: 'error',
                    status: 500,
                    message: "The distance between the markers should span at least 50% of the total height."
                });
                return;
            }
            setPolyline([])
            let boundingHeightfactor = boundsY / floorplanHeight
            setBhFactor(boundingHeightfactor)
            let boundingHeight = imgHeight * boundingHeightfactor
            setBHeight(boundingHeight)
            if (boundingHeight === Infinity) {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    type: 'error',
                    status: 500,
                    message: "Bounding height is Infinity",
                });
            } else {
                    setHeightClass("classInvisible")
                    setP1Class("classVisible")
                    setDescriptionText("Place each marker on a known geo location and enter their coordinates below. If you do not need geo positioning then choose skip.")
                    setTitle("Upload Floorplan  |  Define Geo Coordinates")
            }
        }

    }

    function widthClick() {
        if (boundsX === null || isNaN(boundsX)) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                status: 500,
                message: "No width provided",
            });
        } else {
            let floorplanWidth = Math.abs(marker1.x - marker2.x)
            if (floorplanWidth < imgWidth / 2) {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    type: 'error',
                    status: 500,
                    message: "The distance between the markers should span at least 50% of the total width."
                });
                return;
            }
            setShowline('y')
            const marker = markerRef.current
            const c1 = [0, marker.getLatLng().lng]
            const c2 = [imgHeight, marker.getLatLng().lng]
            setPolyline([c1, c2])

            let boundingWidthfactor = boundsX / floorplanWidth
            setBwFactor(boundingWidthfactor)
            let boundingWidth = imgWidth * boundingWidthfactor
            setBWidth(boundingWidth)
            if (boundingWidth === Infinity) {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    type: 'error',
                    status: 500,
                    message: "Bounding width is Infinity",
                });
            } else {
                setWidthClass("classInvisible")
                setHeightClass("classVisible")
                setDescriptionText("Pick two places on the map on a vertical line and with a known height. Then Place Marker 1 at one end and Marker 2 at the other end. Caution: the distance between the Markers must be at least 50% of the floorplan height.")
                setTitle("Upload Floorplan  |  Define Height")
            }

        }

    }

    function m1Click() {
        if (m1Lat === null || isNaN(m1Lat) || m1Lng === null || isNaN(m1Lng) || m2Lat === null || isNaN(m2Lat) || m2Lng === null || isNaN(m2Lng)) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                status: 500,
                message: "No latitude provided",
            });
        } else {
            getGeoCorners(marker1.x * bwFactor, marker1.y * bhFactor, marker2.x * bwFactor, marker2.y * bhFactor, m1Lng, m1Lat, m2Lng, m2Lat) //check order !!!
            setUploadBtnVisible("classVisible")
            setDescriptionText("Click Upload to complete the floorplan upload.")
            setShowDelete(false)
        }

    }
    function skipClick() {
        setShowDelete(false)
        setShowGeo(false)
        setP1Class("classInvisible")
        setUploadBtnVisible("classVisible")
        setDescriptionText("Click Upload to complete the floorplan upload.")
    }

    return (

        <>
            <Toast
                setParentToastConfig={setToastConfig}
                toastBody={toastConfig}
            />
            <div className='upload-container'>
                <div className='upload-title'>
                    {title}

                </div>
                <div className={browseClass}>
                    <div className="upload-browseFilesText">
                        <label htmlFor="fileUpload" className="customUploadBtnBrowse">
                            Browse Files

                        </label>
                        <div className='uploadFloorplan-noteContainer'>
                            <div className='uploadFloorplan-noteText'>
                                Note:
                            </div>

                            <ul>
                                <li>Max file size can be 10 MB</li>
                                <li>Supported file formats .png, .jpeg, .jpg</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {showLeaflet()}
                <input
                    id="fileUpload"
                    type="file"
                    name="myImage"
                    className="uploadBtn"
                    onChange={(event) => {
                        const oFile = document.getElementById("fileUpload").files[0]
                        const extension = oFile.type
                        if (oFile.size > 10000000) // 10MB for bytes.
                        {
                            setToastConfig({
                                ...toastConfig,
                                isVisible: true,
                                type: 'error',
                                message: "The image is larger than 10MB",
                                status: 500,
                            });
                        } else if (extension === "image/jpeg" || extension === "image/png" || extension === "image/jpg") {
                            setSelectedImage(event.target.files[0]);
                            setBrowseClass("classInvisible")
                        } else {
                            setToastConfig({
                                ...toastConfig,
                                isVisible: true,
                                type: 'error',
                                status: 500,
                                message: "Image must be one of .png, .jpeg, .jpg",
                            });
                        }
                    }}
                    onClick={
                        (event) => {
                            event.target.value = null
                        }
                    }
                />
                <div className='upload-description'>
                    {descriptionText}
                </div>
                <form
                    action="/action_page.php"
                    className='upload-floorplanForm'
                    onChange={(event) => {
                        if (event.target.id === "xMax") {
                            setBoundsX(event.target.value)
                        }
                        if (event.target.id === "yMax") {
                            setBoundsY(event.target.value)
                        }
                        if (event.target.id === "m1lat") {
                            setM1Lat(event.target.value)
                        }
                        if (event.target.id === "m1lng") {
                            setM1Lng(event.target.value)
                        }
                        if (event.target.id === "m2lat") {
                            setM2Lat(event.target.value)
                        }
                        if (event.target.id === "m2lng") {
                            setM2Lng(event.target.value)
                        }
                    }}
                >
                    <div className={widthClass}>
                        <div className='upload-input'>
                            <div className='upload-inputLabel'>
                                Width (X) in Meters
                            </div>
                            <input className="upload-form" type="number" id="xMax" name="xMax" /><br></br>
                        </div>

                        <div className='upload-setBtn' onClick={widthClick}>
                            Set Width
                        </div>
                    </div>
                    <div className={heightClass}>
                        <div className='upload-input'>
                            <div className='upload-inputLabel'>
                                Height (Y) in Meters
                            </div>

                            <input className="upload-form" type="number" id="yMax" name="yMax" /><br></br>
                        </div>
                        <div className='upload-setBtn' onClick={heightClick}>
                            Set Height
                        </div>
                    </div>
                    <div className={p1Class}>
                        <div className='upload-input'>
                            <div className='upload-inputLabel'>
                                Marker 1 latitude
                            </div>
                            <input className="upload-form" type="number" id="m1lat" name="m1lat" /><br></br>
                        </div>
                        <div className='upload-input'>

                            <div className='upload-inputLabel'>
                                Marker 1 longitude
                            </div>
                            <input className="upload-form" type="number" id="m1lng" name="m1lng" /><br></br>
                        </div>
                        <div className='upload-input'>

                            <div className='upload-inputLabel'>
                                Marker 2 latitude
                            </div>
                            <input className="upload-form" type="number" id="m2lat" name="m2lat" /><br></br>
                        </div>
                        <div className='upload-input'>

                            <div className='upload-inputLabel'>
                                Marker 2 longitude
                            </div>
                            <input className="upload-form" type="number" id="m2lng" name="m2lng" /><br></br>
                        </div>
                        
                        <div className='upload-yesNoBtnContainer'>
                            <div className='upload-setBtn2' onClick={m1Click}>
                                Save
                            </div>
                            <div className='upload-setBtn2' onClick={skipClick}>
                                Skip
                            </div>
                        </div>
                        
                    </div>
                    <div className={p2Class}>
                    </div>
                </form>

                <div className={showDelete ? 'uploadFloorplan-DeleteBtn' : "uploadFloorplan-DeleteBtn-hide"} onClick={handleCancel}>
                    Cancel
                </div>

                <div className={uploadBtnVisible}>
                    <div className="customUploadFile" onClick={handleSubmit}>
                        Upload
                    </div>
                </div>
            </div>
        </>
    );
};

export default Upload