import React from 'react';
import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { MapContainer, Marker, Popup, ImageOverlay, Pane } from 'react-leaflet'
import SpacesService from '../../api/spaces.service';
import AssetsService from '../../api/assets.service';
import { latLngBounds } from 'leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { EditControl } from "react-leaflet-draw";
import {
    FeatureGroup,
    Polygon,
    Tooltip,
    Polyline,
} from "react-leaflet";
import circle from "../../assets/markers/circle.png";
import greenCircle from "../../assets/markers/greenCircle.png";
import redCircle from "../../assets/markers/redCircle.png";
import yellowCircle from "../../assets/markers/yellowCircle.png"
import './floorplan.css'
import Blue from "../../assets/markers/blue2.js";
import Green from "../../assets/markers/green.js";
import Red from "../../assets/markers/red.js";
import { CRS } from 'leaflet';
import "leaflet-draw/dist/leaflet.draw-src.css";
import Toast from 'components/Toast';
import { MarkerMuster } from "react-leaflet-muster";
import 'leaflet/dist/leaflet.css'
import { useHistory } from "react-router";
import TenantService from "../../api/tenant.service";
import Switch from 'devextreme-react/switch';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

function DraggableMarker({ anchor, toastConfigHandler, spaceId, tooltip, drag }) {
    const markerRef2 = useRef(null)
    const [position, setPosition] = useState([anchor.spaceCoordinates.y, anchor.spaceCoordinates.x])
    let serial = anchor.serialNumber
    let id = anchor.id
    const eventHandlers2 = useMemo(
        () => ({
            dragend() {
                const marker = markerRef2.current
                let newPosition = { y: marker.getLatLng().lat, x: marker.getLatLng().lng }
                let newId = marker.options.data
                SpacesService.editDevice(newId, newPosition, serial).then((response) => {
                    const messageType = response.status===200 ? 'success' : 'error';
                    toastConfigHandler(true, messageType, response.data.message, response.status);
                    setPosition(marker.getLatLng())
                })
            },
        }),
        [],
    )
    if (tooltip === "serial") {
        return (
            <Marker
                draggable={drag}
                eventHandlers={eventHandlers2}
                position={position}
                data={id}
                key={serial}
                id={serial}
                ref={markerRef2}>
                <Tooltip direction="top" offset={[-15, -12]} opacity={1} permanent>
                    Serial Number: {serial}
                </Tooltip>
            </Marker>
        )
    }
    if (tooltip === "name") {
        return (
            <Marker
                draggable={drag}
                eventHandlers={eventHandlers2}
                position={position}
                data={id}
                key={serial}
                id={serial}
                ref={markerRef2}>
                <Tooltip direction="top" offset={[-15, -12]} opacity={1} permanent>
                    Name: {serial}
                </Tooltip>
            </Marker>
        )
    }
    else if (tooltip === "date") {
        return (
            <Marker
                draggable={false}
                eventHandlers={eventHandlers2}
                position={position}
                data={id}
                key={serial}
                id={serial}
                ref={markerRef2}>
                <Popup >
                    Date: {anchor.serialNumber}
                </Popup>
            </Marker>
        )
    }
}

function DraggableMarkerGateway({ gateway, toastConfigHandler, tooltip, drag, spaceId }) {
    const markerRef2 = useRef(null)
    const [position, setPosition] = useState([gateway.spaceCoordinates.y, gateway.spaceCoordinates.x])
    let id = gateway.id
    let name = gateway.name
    
    const eventHandlers2 = useMemo(
        () => ({
            dragend() {
                const marker = markerRef2.current
                let newPosition = { y: marker.getLatLng().lat, x: marker.getLatLng().lng }
                let newId = marker.options.data
                console.log('space id: ', spaceId);
                SpacesService.editGateway(newId, newPosition, spaceId).then((response) => {
                    const messageType = response.status===200 ? 'success' : 'error';
                    toastConfigHandler(true, messageType, response.data.message, response.status);
                    setPosition(marker.getLatLng())
                })
            },
        }),
        [],
    )
    if (tooltip === "name") {
        return (
            <Marker
                draggable={drag}
                eventHandlers={eventHandlers2}
                position={position}
                data={id}
                key={name}
                id={name}
                ref={markerRef2}>
                <Tooltip direction="top" offset={[-15, -12]} opacity={1} permanent>
                    Name: {name}
                </Tooltip>
            </Marker>
        )
    }
}

//myMarkers = true or myMarkers = [id: string, serialNumber: string, spaceCoordinates: { x: int, y: int }]
//type = "anchors" or "assets" or "assetsSpace" or "polyline" or "polygon"
export default function FloorplanPopup({ spaceId, myMarkers, type, windowType, moveMarkers, zoneEditControl, highlightRow, hoveredCoordinates }) {
    console.log('HOVERED COORDINATES: ', hoveredCoordinates);
    const history = useHistory();
    const listRef = useRef();
    const markerRef = useRef(null)
    const [floorplanUrl, setFloorplanUrl] = useState("https://flevix.com/wp-content/uploads/2019/12/Barline-Loading-Images-1.gif");
    const [anchors, setAnchors] = useState([]);
    const [gateways, setGateways] = useState([]);
    const [singleAsset, setSingleAsset] = useState()
    const [bounds, setBounds] = useState(new latLngBounds([0, 0], [25, 35]));
    const [center, setCenter] = useState([0, 0])
    const [polygon, setPolygon] = useState([])
    const [polyline, setPolyline] = useState([])
    const [polyline2, setPolyline2] = useState([])
    const [showFloorplan, setShowFloorplan] = useState(false)
    const [polygonEditType, setPolygonEditType] = useState("")
    const [otherPolygonArray, setOtherPolygonArray] = useState([])
    const [otherPolygonNames, setOtherPolygonNames] = useState([])
    const [show5m, setShow5m] = useState(false)
    const [show1m, setShow1m] = useState(false)
    const tenant = TenantService.getTenant()
    const [toastConfig, setToastConfig] = React.useState({
        isVisible: false,
        type: 'info',
        message: '',
        displayTime: 5000,
    });
    const [editAllowed, setEditAllowed] = useState(false)
    const [windowSize, setWindowSize] = useState({ height: '100%', width: '100%' })
    const zIndexes = {
        low: 100,
        medium: 200,
        high: 300
      };
    // pulling assets at 10 seconds
    useEffect(() => {
        const recursiveAssetsPlanRequest = setInterval(() => {
            if(type === 'assets') {
              pullAssets(spaceId);
            }
        }, 10000);
        return () => clearInterval(recursiveAssetsPlanRequest);
    });

    const toastConfigHandler=(visible,type,message,status)=>{
        setToastConfig({
            isVisible:visible,
            type:type,
            message:message,
            status: status,
        });
    }

    function checkRole() {
        var role = JSON.parse(localStorage.getItem("role"));
        let canEdit = false
        for (let i = 0; i < role.length; i++) {
            if (role[i].name === "Asset Attributes") {
                if (role[i].write === true) {
                    canEdit = true
                }
            }
        }
        if (canEdit && moveMarkers) {
            setEditAllowed(true)
        }
    }

    function checkWindow() {
        if (windowType !== undefined) {
            setWindowSize(windowType)
        }
    }

    function pullAssets(){
        AssetsService.getAssetsInSpace(spaceId).then((res) => {
            let response=res.data
            let correctedAssets = []
            for (let i = 0; i < response.data.length; i++) {
                correctedAssets.push({
                    id: response.data[i].id,
                    serialNumber: response.data[i].name,
                    spaceCoordinates: response.data[i].localCoordinates
                })
            }
            setAnchors(correctedAssets)
        })
    }

    function getUrl(spaceId) {
        if (spaceId === "") {
            setFloorplanUrl("http://rpo.redlore.com/api/places/floorplan/a32b2298-bff7-44f8-a823-fa6b7fe1b8f7")
            return
        }
        let myFloorplan = null
        SpacesService.getSpaceFloorplan(spaceId).then((res) => {
            myFloorplan = URL.createObjectURL(res.data)
            setFloorplanUrl(myFloorplan)
        })
        SpacesService.getSpace(spaceId).then((res) => {
            let response=res.data
            let polygonCoords = response.data.localCoordinates
            let correctedPolygonCoords = []
            if (polygonCoords !== null) {
                if (response.data.id !== response.data.referenceSpace.id) {
                    setPolygonEditType("edit")
                }
                for (let i = 0; i < polygonCoords.length; i++) {
                    correctedPolygonCoords.push([polygonCoords[i][1], polygonCoords[i][0]])
                }
            } else {
                setPolygonEditType("create")
            }
            SpacesService.getChildZones(response?.data?.referenceSpace?.id).then((res) => {
                let response=res.data
                let localCoordsArray = []
                let localCoordsArray2 = []
                let nameArray = []
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].localCoordinates !== null && response.data[i].id !== spaceId) {
                        localCoordsArray.push([response.data[i].localCoordinates])
                        let localCoords = []
                        for (let j = 0; j < response.data[i].localCoordinates.length; j++) {
                            localCoords.push([response.data[i].localCoordinates[j][1], res.data.data[i].localCoordinates[j][0]])
                        }
                        localCoordsArray2.push(localCoords)
                        nameArray.push(response.data[i].name)
                    }

                }
                setOtherPolygonArray(localCoordsArray2)
                setOtherPolygonNames(nameArray)
            })
            setPolygon(correctedPolygonCoords)
            SpacesService.getSpace(response.data.referenceSpace.id).then((res2) => {
                let response2=res2.data
                let refSpaceBounds = response2.data.localCoordinates
                let correctedRefSpaceBounds = []
                for (let i = 0; i < refSpaceBounds.length; i++) {
                    correctedRefSpaceBounds.push([refSpaceBounds[i][1], refSpaceBounds[i][0]])
                }
                let coords = correctedRefSpaceBounds
                let xMax = 0
                let yMax = 0
                for (let i = 0; i < coords.length; i++) {
                    if (coords[i][0] > xMax) {
                        xMax = coords[i][0]
                    }
                    if (coords[i][1] > yMax) {
                        yMax = coords[i][1]
                    }
                }
                setBounds(new latLngBounds([0, 0], [xMax, yMax]))
                setCenter([0 + xMax / 2, 0 + yMax / 2])
            })
        })
        if (type === 'assets') {
            pullAssets();
        }
        if (type === 'assetsSpace') {
            let coords = [myMarkers[0].spaceCoordinates.y, myMarkers[0].spaceCoordinates.x]
            setSingleAsset({
                coords: coords,
                serialNumber: myMarkers[0].serialNumber
            })
        }
        if (type === 'gateways'){
            SpacesService.getGatewaysInSpace(spaceId).then((res) => {
                setGateways(res.data.data)
            })
        }
        if (type === 'anchors') {
            SpacesService.getAnchorsInSpace(spaceId).then((res) => {
                setAnchors(res.data.data)
            })
        } else if (type === 'polyline') {
            var poly = []
            var poly2 = []
            for (let i = 0; i < myMarkers.length; i++) {
                if (myMarkers.length === 1){
                    poly.push({
                        coords: [myMarkers[i].spaceCoordinates.y, myMarkers[i].spaceCoordinates.x],
                        time: myMarkers[i].serialNumber,
                        last: true,
                        start: false,
                        zIndex: 'high',
                        rowId: myMarkers[i].parentId
                    })
                } else if (i === 0) {
                    poly.push({
                        coords: [myMarkers[i].spaceCoordinates.y, myMarkers[i].spaceCoordinates.x],
                        time: myMarkers[i].serialNumber,
                        last: false,
                        start: true,
                        zIndex: 'medium',
                        rowId: myMarkers[i].parentId
                    })
                } else if (i === myMarkers.length - 1) {
                    poly.push({
                        coords: [myMarkers[i].spaceCoordinates.y, myMarkers[i].spaceCoordinates.x],
                        time: myMarkers[i].serialNumber,
                        last: true,
                        start: false,
                        zIndex: 'high',
                        rowId: myMarkers[i].parentId
                    })
                }else {
                    poly.push({
                        coords: [myMarkers[i].spaceCoordinates.y, myMarkers[i].spaceCoordinates.x],
                        time: myMarkers[i].serialNumber,
                        last: false,
                        start: false,
                        zIndex: 'low',
                        rowId: myMarkers[i].parentId,
                    })
                }
                poly2.push([myMarkers[i].spaceCoordinates.y, myMarkers[i].spaceCoordinates.x])
            }
            setPolyline(poly)
            setPolyline2(poly2)
        } else if (myMarkers === 'polygon') {
        }
    }
    useEffect(() => {
        // setPolygon([])
        // setOtherPolygonArray([])
        // setOtherPolygonNames([])
        checkWindow()
        checkRole()
        // setBounds(new latLngBounds([0, 0], [25, 35]))
        // setCenter([0, 0])
        getUrl(spaceId)
        setShowFloorplan(true)
    }, [spaceId]);

    useEffect(() => {
    }, [anchors, gateways]);

    useEffect(() => {
        // setBounds(new latLngBounds([0, 0], [25, 35]))
        // setCenter([0, 0])
        // setPolygon([])
        // setOtherPolygonArray([])
        // setOtherPolygonNames([])
        getUrl(spaceId)
    }, [myMarkers]);

    useEffect(() => {
    }, [center]);

    const handleMarkerClick = (rowIndex) => {
        highlightRow(rowIndex);
    };

    function MyPolyline() {
        console.log('TYPE: ', type);
        if (type === 'polyline' || type ===  'polyline2') {
            var startIcon = new L.icon({
                iconUrl: greenCircle,
                iconSize: [10, 10], // size of the icon
                iconAnchor: [5, 5], // point of the icon which will correspond to marker's location
                popupAnchor: [0, -2] // point from which the popup should open relative to the iconAnchor
            })
            var myIcon = new L.icon({
                iconUrl: circle,
                iconSize: [10, 10], // size of the icon
                iconAnchor: [5, 5], // point of the icon which will correspond to marker's location
                popupAnchor: [0, -2] // point from which the popup should open relative to the iconAnchor
            })
            var lastIcon = new L.icon({
                iconUrl: redCircle,
                iconSize: [10, 10], // size of the icon
                iconAnchor: [5, 5], // point of the icon which will correspond to marker's location
                popupAnchor: [0, -2] // point from which the popup should open relative to the iconAnchor
            })
            var highlightIcon = new L.icon({
                iconUrl: yellowCircle,
                iconSize: [10, 10], // size of the icon
                iconAnchor: [5, 5], // point of the icon which will correspond to marker's location
                popupAnchor: [0, -2] // point from which the popup should open relative to the iconAnchor
            })
            if (type === 'polyline2'){
                return (
                    <>
                        {polyline.map((anchor, i) =>
                            //eventHandlers={eventHandlers} !!!
                            // <Pane zIndex={zIndexes[anchor.zIndex]}>
                            // <Pane zIndex={anchor.last ? 300 : anchor.start ? 200 : 100}>
                            <Marker id={i} key={i} 
                            position={anchor.coords} draggable={false} 
                            icon={anchor.last ? lastIcon : anchor.start ? startIcon : myIcon} 
                            ref={markerRef} 
                            zIndexOffset={anchor.last ? 300 : anchor.start ? 200 : 100}
                            eventHandlers={{
                                click: () => handleMarkerClick(i)
                              }}>
                                <Tooltip direction="top" offset={[0, 0]} opacity={1}>
                                    Date: {anchor.time}
                                </Tooltip>
                            </Marker>
                            //  </Pane>
                        )}
                        {/* <Polyline positions={polyline2}>
                        </Polyline> */}
                    </>
                )
            } else{
                return (
                    <>
                        {polyline.map((anchor, i) =>
                            //eventHandlers={eventHandlers} !!!
                            // <Pane zIndex={zIndexes[anchor.zIndex]}>
                                
                            // <Pane zIndex={anchor.last ? 300 : anchor.start ? 200 : 100}>
                            <Marker id={i} key={i} position={anchor.coords} draggable={false} 
                            icon={anchor.last ? lastIcon : anchor.start ? startIcon : myIcon} 
                            // ref={markerRef} 
                            eventHandlers={{
                                click: () => handleMarkerClick(i)
                              }}
                              zIndexOffset={anchor.last ? 300 : anchor.start ? 200 : 100}
                            >
                                <Tooltip direction="top" offset={[0, 0]} opacity={1}>
                                    Date: {anchor.time}
                                </Tooltip>
                            </Marker>
                        )}
                        {hoveredCoordinates && (
                              <Marker
                                position={[hoveredCoordinates.y, hoveredCoordinates.x]} // Use y for latitude, x for longitude
                                icon={highlightIcon} // You can change the icon here if needed
                                zIndexOffset={400}
                              >
                              </Marker>
                            )}
                        <Polyline positions={polyline2}>
                        </Polyline>
                    </>
                )
            }
           
        }
        else {
            return (<></>)
        }
    }

    function MyGridlineLat() {
        if (show1m) {
            let latMax = bounds._northEast.lat
            let latMin = bounds._southWest.lat
            let lngMax = bounds._northEast.lng
            let lngMin = bounds._southWest.lng
            let lngDiff = lngMax - lngMin
            let lineArray = []
            let spacing = 1
            for (let i = 0; i < (lngDiff / spacing); i++) {
                lineArray.push([[latMin, i * spacing], [latMax, i * spacing]])
            }
            return (
                <>
                    {lineArray.map((line, i) =>
                        <Polyline key={i} positions={line} color={"red"} weight={1}>
                        </Polyline>
                    )}
                </>
            )
        }
        else {
            return (<></>)
        }
    }

    function MyGridlineLng() {
        if (show1m) {
            let latMax = bounds._northEast.lat
            let latMin = bounds._southWest.lat
            let latDiff = latMax - latMin
            let lngMax = bounds._northEast.lng
            let lngMin = bounds._southWest.lng
            let lineArray = []
            let spacing = 1
            for (let i = 0; i < (latDiff / spacing); i++) {
                lineArray.push([[i * spacing, lngMin], [i * spacing, lngMax]])
            }
            return (
                <>
                    {lineArray.map((line, i) =>
                        <Polyline key={i} positions={line} color={"red"} weight={1}>
                        </Polyline>
                    )}
                </>
            )
        }
        else {
            return (<></>)
        }
    }

    function MyGridlineLat5() {
        if (show5m) {
            let latMax = bounds._northEast.lat
            let latMin = bounds._southWest.lat
            let lngMax = bounds._northEast.lng
            let lngMin = bounds._southWest.lng
            let lngDiff = lngMax - lngMin
            let lineArray = []
            let spacing = 5
            for (let i = 0; i < (lngDiff / spacing); i++) {
                lineArray.push([[latMin, i * spacing], [latMax, i * spacing]])
            }
            return (
                <>
                    {lineArray.map((line, i) =>
                        <Polyline key={i} positions={line} color={"red"} weight={2} visible={false}>
                        </Polyline>
                    )}
                </>
            )
        }
        else {
            return (<></>)
        }
    }

    function MyGridlineLng5() {
        if (show5m) {
            let latMax = bounds._northEast.lat
            let latMin = bounds._southWest.lat
            let latDiff = latMax - latMin
            let lngMax = bounds._northEast.lng
            let lngMin = bounds._southWest.lng
            let lineArray = []
            let spacing = 5
            for (let i = 0; i < (latDiff / spacing); i++) {
                lineArray.push([[i * spacing, lngMin], [i * spacing, lngMax]])
            }
            return (
                <>
                    {lineArray.map((line, i) =>
                        <Polyline key={i} positions={line} color={"red"} weight={2}>
                        </Polyline>
                    )}
                </>
            )
        }
        else {
            return (<></>)
        }
    }

    useEffect(() => {
    }, [show5m]);

    function setGrid5(e) {
        setTimeout(function () {
            if(e.value === true){
                setShow5m(true)
            } else{
                setShow5m(false)
            }
        }, 1);
    }

    function setGrid(e) {
        setTimeout(function () {
            if(e.value === true){
                setShow1m(true)
            } else{
                setShow1m(false)
            }
            // setShow1m(!show1m)
        }, 1);
    }

    // function manageZone(e){
    //     setTimeout(function () {
    //         if(e.value === true){
    //             zoneEditControl  = true
    //             MyPolyline(type = 'polyline2')
    //         } else{
    //             zoneEditControl  = false
    //             MyPolyline(type = 'polyline')
    //         }
    //         // setShow1m(!show1m)
    //     }, 1);
    // }

    function MyPolygon() {
        if (type === 'polygon') {
            return (
                <Polygon positions={polygon}>
                </Polygon>
            )
        }
        else {
            return (<></>)
        }
    }

    const _onEdited = e => {
        var obj = e.layers._layers;
        var myVal = Object.keys(obj)[0]
        let latlngs = e.layers._layers[myVal]._latlngs[0]
        let xyCoords = []
        for (let i = 0; i < latlngs.length; i++) {
            xyCoords.push([latlngs[i].lng, latlngs[i].lat])
        }
        SpacesService.editSpaceZone(spaceId, xyCoords).then((response) => {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: response.status===200 ? 'success' : 'error',
                status: response.status,
                message: response.data.message,
            });
        })
    };

    const _onCreated = e => {
        document.querySelector('.leaflet-draw-section .leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-top').style.setProperty('--thumb-width', 'hidden');
        document.querySelector('.leaflet-draw.leaflet-control').style.setProperty('--display', 'flex');
        document.querySelector('.leaflet-draw.leaflet-control').style.setProperty('--margin', '0px');
        let layer = e.layer;
        let latlngs = layer.getLatLngs()[0]
        let xyCoords = []
        for (let i = 0; i < latlngs.length; i++) {
            xyCoords.push([latlngs[i].lng, latlngs[i].lat])
        }
        SpacesService.editSpaceZone(spaceId, xyCoords).then((response) => {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: response.status===200 ? 'success' : 'error',
                status: response.status,
                message: response.data.message,
            });
        })
    };

    const _onDeleteStart = e => {
        if (document.querySelector('.leaflet-draw-tooltip-single') === null) {
            setTimeout(function () {
                if (document.querySelector('.leaflet-draw-tooltip-single') !== null) {
                    document.querySelector('.leaflet-draw-tooltip-single').style.setProperty('--tooltip-visible', 'hidden', "important")
                }
            }, 1);

        } else {
            document.querySelector('.leaflet-draw-tooltip-single').style.setProperty('--tooltip-visible', 'hidden', "important")
        }
        if (document.querySelector('.leaflet-draw-actions li:first-child') === null) {
            setTimeout(function () {

                if (document.querySelector('.leaflet-draw-actions li:first-child') === null) {
                    document.querySelector('.leaflet-draw-actions li:first-child').style.setProperty('--save-visible', 'hidden');
                    document.querySelector('.leaflet-draw-actions li:first-child').style.setProperty('--save-width', '0px');
                }
            }, 1)
        }
        else {
            document.querySelector('.leaflet-draw-actions li:first-child').style.setProperty('--save-visible', 'hidden');
            document.querySelector('.leaflet-draw-actions li:first-child').style.setProperty('--save-width', '0px');
        }
    }

    const _onDeleteStop = e => {
        if (document.querySelector('.leaflet-draw-tooltip-single') === null) {
            setTimeout(function () {
                if (document.querySelector('.leaflet-draw-tooltip-single') !== null) {
                    document.querySelector('.leaflet-draw-tooltip-single').style.setProperty('--tooltip-visible', 'visible', "important")
                }
            }, 1);
        } else {
            document.querySelector('.leaflet-draw-tooltip-single').style.setProperty('--tooltip-visible', 'visible', "important")
        }
        if (document.querySelector('.leaflet-draw-actions li:first-child a') !== null) {
            document.querySelector('.leaflet-draw-actions li:first-child a').style.setProperty('--save-visible', 'visible');
            document.querySelector('.leaflet-draw-actions li:first-child').style.setProperty('--save-width', 'auto');
            if (document.querySelector('.leaflet-draw-actions .leaflet-tooltip') !== null) {
                document.querySelector('.leaflet-draw-actions .leaflet-tooltip').style.setProperty('--tooltip-visible', 'visible');
            }
        }
    }

    const _onEditStart = e => {
    }

    const _onEditStop = e => {
    }

    const _onDeleted = e => {
        document.querySelector('.leaflet-draw-section .leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-top').style.setProperty('--thumb-width', 'visible');
        document.querySelector('.leaflet-draw.leaflet-control').style.setProperty('--display', 'block');
        document.querySelector('.leaflet-draw.leaflet-control').style.setProperty('--margin', '25px');
        SpacesService.deleteSpaceZone(spaceId).then((response) => {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: response.status===200 ? 'success' : 'error',
                status: response.status,
                message: response.data.message,
            });
        })
    };

    
    const [tooltipContents, setTooltipContents] = useState(otherPolygonNames.map(() => null));

    const handleMouseOver = useCallback((index, content) => {
        setTooltipContents((prevContents) => {
          const newContents = [...prevContents];
          newContents[index] = content;
          return newContents;
        });
      }, []);
    
      const handleMouseOut = useCallback((index) => {
        setTooltipContents((prevContents) => {
          const newContents = [...prevContents];
          newContents[index] = null;
          return newContents;
        });
      }, []);
    

    function otherPolygons(localCoordsArray, type) {
        if (type === 'polyline'){
            return (
                localCoordsArray.map((polygon1, i) =>
                    <Polygon positions={polygon1} fillColor={'#B51716'} color={'#B51716'} key={i}
                        eventHandlers={{
                            mouseover: () => handleMouseOver(i, otherPolygonNames[i]),
                            mouseout: () => handleMouseOut(i),
                        }}
                    >
                        {tooltipContents[i] && <Tooltip direction="top" offset={[-15, -12]} opacity={1} permanent>
                            {tooltipContents[i]}
                        </Tooltip>}
                    </Polygon>
                )
            )
        } else {
            return (
                localCoordsArray.map((polygon1, i) =>
                    <Polygon positions={polygon1} fillColor={'#B51716'} color={'#B51716'} key={i}>
                        <Tooltip direction="top" offset={[-15, -12]} opacity={1} permanent>
                            {otherPolygonNames[i]}
                        </Tooltip>
                    </Polygon>
                )
            )
        }
    }

    function onMounted(num) {
        if (document.querySelector('.leaflet-draw-section .leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-top') !== null) {
            if (polygonEditType === "create") {
                document.querySelector('.leaflet-draw-section .leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-top').style.setProperty('--thumb-width', 'visible');
                document.querySelector('.leaflet-draw.leaflet-control').style.setProperty('--display', 'block');
                document.querySelector('.leaflet-draw.leaflet-control').style.setProperty('--margin', '25px');
            } else {
                document.querySelector('.leaflet-draw-section .leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-top').style.setProperty('--thumb-width', 'hidden');
                document.querySelector('.leaflet-draw.leaflet-control').style.setProperty('--display', 'flex');
                document.querySelector('.leaflet-draw.leaflet-control').style.setProperty('--margin', '0px');
            }
        }
    }

    function delayFn() {
        setTimeout(function () { onMounted(1) }, 1);
    }

    function editFloorPlanFn() {
       if(zoneEditControl){
            return (
                <EditControl
                    position="topleft"
                    onEdited={_onEdited}
                    onCreated={_onCreated}
                    onDeleted={_onDeleted}
                    onDeleteStart={_onDeleteStart}
                    onDeleteStop={_onDeleteStop}
                    onEditStart={_onEditStart}
                    onEditStop={_onEditStop}
                    draw={{
                        polyline: false,
                        rectangle: true,
                        circlemarker: false,
                        circle: false,
                        marker: false,
                        polygon: true
                    }}
                />
            )
        }
    }
    function featureGroup(type) {
        return (
            <>
                <FeatureGroup>
                {editFloorPlanFn() }
                    <Polygon positions={polygon}>
                    </Polygon>

                </FeatureGroup>
                {otherPolygons(otherPolygonArray, type ? type : "")}
            </>
        )
    }

    function editPolygon() {
        if (type === 'polygonEdit' || 'anchors' || 'gateways') {
            return (
                featureGroup(type)
            )
        }
    }

    function allStorage() {
        localStorage.removeItem(tenant + "assets")
        // look!!!
        // return values;
    }

    function onClusterClick(e) {
        const markerArray = e.layer._markers
        const markerIds = []
        for (let i = 0; i < markerArray.length; i++) {
            markerIds.push(markerArray[i].options.id)
        }
        if (markerArray.length !== 0 && markerArray.length !== 1) {
            allStorage()
            history.push({
                pathname: `/assets`,
                state: {
                    markers: markerIds
                }
            })
        }
    }

    function anchorMarkers() {
        let anchorsFiltered = []
        for (let i = 0; i < anchors.length; i++) {
            if (anchors[i].spaceCoordinates !== null) {
                anchorsFiltered.push(anchors[i])
            }
        }
        let gatewaysFiltered = []
        for (let i = 0; i < gateways.length; i++) {
            if (gateways[i].spaceCoordinates !== null) {
                gatewaysFiltered.push(gateways[i])
            }
        }
        if (type === 'anchors') {
            return (
                anchorsFiltered.map((anchor, i) =>
                    <DraggableMarker anchor={anchor} toastConfigHandler={toastConfigHandler} spaceId={spaceId} tooltip={"serial"} drag={editAllowed}>
                    </DraggableMarker>
                )
            )
        }
        if (type === 'gateways') {
            return (
                gatewaysFiltered.map((gateway, i) =>
                    <DraggableMarkerGateway gateway={gateway} toastConfigHandler={toastConfigHandler} spaceId={spaceId} tooltip={"name"} drag={editAllowed}>
                    </DraggableMarkerGateway>
                )
            )
        }
        else if (type === 'assets') {
            return (
                <MarkerMuster
                    chunkedLoading
                    onClick={(e) => { onClusterClick(e) }}
                >
                    <>
                        {anchorsFiltered.map((anchor, i) => (
                            <Marker id={anchor.id} key={i} position={[anchor.spaceCoordinates.y, anchor.spaceCoordinates.x]} draggable={false} ref={markerRef}>
                                <Tooltip direction="top" offset={[-15, -12]} opacity={1} permanent>
                                    Name: {anchor.serialNumber}
                                </Tooltip>
                            </Marker>
                        )
                        )}
                    </>
                </MarkerMuster>
            )
        } else if (type === 'assetsSpace') {
            return (

                <Marker key={1} position={singleAsset.coords} draggable={false} ref={markerRef}>
                    <Tooltip direction="top" offset={[-15, -12]} opacity={1} permanent>
                        Name: {singleAsset.serialNumber}
                    </Tooltip>
                </Marker>
            )

        } else {
            return (
                anchorsFiltered.map((anchor, i) =>
                    <Marker id={anchor.id} key={i} position={[anchor.spaceCoordinates.y, anchor.spaceCoordinates.x]} draggable={false} ref={markerRef}>
                        <Popup >
                            Date: {anchor.serialNumber}
                        </Popup>
                    </Marker>
                )
            )
        }
    }


    function floorplanMap() {

        if (showFloorplan && center[0] !== 0 && center[1] !== 0) {
            let s = listRef.current.clientWidth
            if (listRef.current.clientHeight < s) {
                s = listRef.current.clientHeight
            }
            // let z;
            // if (type === 'polyline') z = (Math.log(s / 15.625) / Math.log(2)) - 2.4
            let z = (Math.log(s / 15.625) / Math.log(2)) - 1.5
            return (
                <>
                    <MapContainer
                        id="map"
                        center={center}
                        style={{ height: '100%', width: '100%' }}
                        zoom={z}
                        min-zoom={4.2}
                        maxZoom={7}
                        zoomSnap={0}
                        crs={CRS.Simple}
                        whenReady={() => {
                            delayFn()
                        }}
                    >
                        <ImageOverlay
                            url={floorplanUrl}
                            bounds={bounds}
                            style={{ height: '100px', width: '100px' }}
                            opacity={1}
                            zIndex={10}
                        />
                        {editPolygon()}
                        {anchorMarkers()}
                        <MyPolyline>
                        </MyPolyline>
                        <MyGridlineLng></MyGridlineLng>
                        <MyGridlineLat></MyGridlineLat>
                        <MyGridlineLng5></MyGridlineLng5>
                        <MyGridlineLat5></MyGridlineLat5>
                        <MyPolygon>
                        </MyPolygon>
                        <div className={(type === "polyline") ? 'legend' : 'legendNone'}>
                            <div className='legendText'>
                                <Green></Green><br></br>
                                <Blue></Blue><br></br>
                                <Red></Red>
                            </div>
                            <div className='legendTextBeside'>
                                Starting position <br></br>
                                Intermediate position <br></br>
                                Ending position
                            </div>
                        </div>
                        <div className='floorplan-5mGrid'>
                            5m Grid: &nbsp;
                            <Switch defaultValue={false} 
                                switchedOnText="ON"
                                switchedOffText="OFF"
                                onValueChanged={setGrid5}
                            />
                        </div>
                        <div className='floorplan-1mGrid' >
                            1m Grid: &nbsp;
                            <Switch defaultValue={false} 
                                switchedOnText="ON"
                                switchedOffText="OFF"
                                onValueChanged={setGrid}
                            />
                        </div>
                        {/* <div className='floorplan-toggle'>
                            Zones: &nbsp;
                                <Switch defaultValue={false} 
                                    switchedOnText="ON"
                                    switchedOffText="OFF"
                                    onValueChanged={() => type === 'polyline' ? 'polyline2' : 'polyline'}
                                />
                        </div> */}
                    </MapContainer>
                </>
            )
        } else {
            <></>
        }
    }
    return (
        <>
            <Toast
                setParentToastConfig={setToastConfig}
                toastBody={toastConfig}
            />
            <div style={{ height: '100%', width: '100%' }} ref={listRef}>
                {floorplanMap()}
            </div>
        </>
    );
}