import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './style.css';
import DataGrid, { Column, Editing,HeaderFilter } from 'devextreme-react/data-grid';
import Toast from 'components/Toast';
import NotificationService from "api/notification.service";

export default function App(props) {
  const [data, setData] = useState([])

  const history = useHistory();
  // const [userPreferences, setUserPreferences] = useState([]);
  const [toastConfig, setToastConfig] = React.useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 5000
  });

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    NotificationService.getListOfTypes().then((value) => {
      setData(value.data.data)
    })
  }

  function saveNotification(){ 
    console.log(data);
    NotificationService.updateUserNotificationPreferences(data).then((response) => {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        status: response.status,
        type: response.status === 200 ? 'success' : 'error',
        message: response.data.message,
      });
    })
    
    setTimeout(() => {
      history.goBack();  
    }, 3000);
    // getData();
  }

  // const handleCheckboxChange = (e) => {
  //   const { data, key, value } = e;
  //   // Update the user preferences based on the changes
  //   // setUserPreferences((prevUserPreferences) => {
  //   //   const updatedPreferences = [...prevUserPreferences];
  //   //   const userIndex = updatedPreferences.findIndex((user) => user.id === data.id);
  //   //   updatedPreferences[userIndex][key] = value;
  //   //   return updatedPreferences;
  //   // });
  // };


  function resetClick(){
    getData()
  }

  function backClick(){
    history.goBack();
  }

  return(
    <>
    <div>
      <div className='form'>
      <h2 >&nbsp; Select Notification</h2>
      <DataGrid
        dataSource={data}
        showBorders={true}
        editing={{
          allowUpdating: true,
          mode: 'cell',
        }}
      >
      <Column dataField="name" caption="Type" dataType="string" allowEditing={false}/>
      <Column dataField="sendEmailAlerts" caption="Email" dataType="boolean">
        <HeaderFilter visible={true} />
        <Editing
          editorType="dxCheckBox"
          allowEditing={true}
        />
      </Column>
      <Column dataField="sendSmsAlerts" caption="SMS" dataType="boolean">
        <HeaderFilter visible={true} />
        <Editing
          editorType="dxCheckBox"
          allowEditing={true}
        />
      </Column>
    </DataGrid>
    </div>
    <div>
      <button className='notification-back' onClick={backClick}>Back</button>
      <button className='notification-cancel' onClick={resetClick}>Reset</button>
      <button className='notification-save' onClick={saveNotification}>Save</button>
    </div>
    </div>
    <Toast
    setParentToastConfig={setToastConfig}
    toastBody={toastConfig}
    />
    </>
  )
}