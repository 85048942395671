import React from 'react';
import axios from 'axios';
import Toast from 'components/Toast';

const Form2 = () => {
  // a local state to store the currently selected file.
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [toastConfig, setToastConfig] = React.useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 5000,
  });

  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData();
    const token = localStorage.getItem('token');
    formData.append("assets", selectedFile);
    try {
      const response = axios({
        method: "post",
        url: "/api/asset/bulk-upload",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          'x-access-token': token
        },
      }).then(response => {
        if (response.data.status === 200) {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: 'success',
            status: 200,
            message: "Upload successful",
          });
          setTimeout(() => {
            window.location.reload()
          }, 2000);
          
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: 'error',
            status: 500,
            message: response.data[0].result,
          });
        }
      })
    } catch (error) {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: 'error',
        message: error,
        status: 500,
      });
    }
  }

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0])
  }

  return (
    <>
      <Toast
        setParentToastConfig={setToastConfig}
        toastBody={toastConfig}
      />
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileSelect} />
        <input type="submit" value="Upload File" />
      </form>
    </>
  )
};

export default Form2;