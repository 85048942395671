import React, { useState, useEffect, useRef } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Paging
} from 'devextreme-react/data-grid';
import moment from 'moment-timezone';
import {
    Chart, Series as ChartSeries, CommonSeriesSettings, Export, Tooltip, Legend as ChartLegend
  } from 'devextreme-react/chart';
import * as DOMPurify from 'dompurify';
import TenantService from 'api/tenant.service';
import OverviewService from 'api/overview.service'
import Toast from 'components/Toast';
import generatePDF, { Margin } from 'react-to-pdf';
import './style.css';
import {LoadPanel as LoadingPanel} from 'devextreme-react/load-panel';
import { DateBox } from 'devextreme-react/date-box';
import SelectBox from 'devextreme-react/select-box';
import { Button } from 'devextreme-react/button';
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export default function Events() {
  const downloadTargetRefEvents = useRef();
  const [data, setData] = useState([])
  const [filterData, setFilterData] = useState([])
  const [events, setEvents] = useState([]);
  const [eventFilters, setEventFilters] = useState([]);
  const [loadingText, setLoadingText] = useState('Loading events...');
  const [eventWarning, setEventWarning] = useState(0);
  const [eventAlarm, setEventAlarm] = useState(0);
  const [eventCrictical, setEventCrictical] = useState(0);
  const [eventInformation, setEventInformation] = useState(0);
  const [heightOfGrid, setHeightOfGrid] = useState('calc(100vh - 51px)');
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  // const [state2, setStateEvents] = useState([
  //   {
  //     startDate: new Date(moment().subtract(24, 'hours').format()),
  //     endDate: new Date(),
  //     key: 'selection',
  //     value: '24 hours',
  //   }
  // ]);
  const [selectedValue, setSelectedValue] = useState('Last 24 hours');
  const tenant = TenantService.getTenant()
  const dropDataGridRef = useRef();
  const dateBoxRefStartEvent = useRef(null);
  const dateBoxRefEndEvent = useRef(null);
  // const now1 = moment();
  // const [startDate, setStartDate] = useState(now1.startOf('day').toDate());
  // const [endDate, setEndDate] = useState(now1.endOf('day').toDate());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [toastConfig, setToastConfig] = React.useState({
    isVisible: false,
    type: 'info',
    message: '',
    displayTime: 5000,
  });
  // const downloadReport = async() => {
  //   setLoading(true);
  //   // await setHeightOfGrid('100%');
  //   await generatePDF(downloadTargetRefEvents, {
  //     filename: `events-${moment().format('YYYY-MM-DD-HH-mm-ss')}.pdf`,
  //     page: { margin: Margin.LARGE }
  //   });
  //   // await setHeightOfGrid('calc(100vh - 51px)');
  //   setLoading(false);
  // }

//   const downloadReport = () => {
//     setLoading(true)
//     const elementToCapture = document.getElementById('full-page-content-events');

//     domtoimage.toPng(elementToCapture)
//       .then((dataUrl) => {
//         // Handle the captured image dataUrl as needed
//         console.log(dataUrl);
//         const pdf = new jsPDF();

//         // Add the captured image as a page in the PDF
//         pdf.addImage(dataUrl, 'PNG', 10, 10, 190, 120); // Adjust the position and size as needed

//         // Save the PDF with a specified filename
//         pdf.save(`Events-${moment().format('YYYY-MM-DD-HH-mm-ss')}.pdf`);
//         downloadReportGridData()
//         setLoading(false)

//       })
//       .catch((error) => {
//         setLoading(false)
//         console.error('Error capturing element:', error);
//       });
//   };

//   const downloadReportGridData = () => {
//     const grid = dropDataGridRef.current.instance;
//     const columns = grid.getVisibleColumns().filter(col => col.dataField).map(col => ({ header: col.caption, dataField: col.dataField }));
//     const rows = grid.getVisibleRows().map(row => {
//       return columns.map(col => {
//         const nestedKeys = col.dataField.split('.');
//         let value = row.data;
//         nestedKeys.forEach(key => {
//           if (value && typeof value === 'object') {
//             value = value[key];
//           } else {
//             value = undefined;
//           }
//         });
//         if (col.dataField === 'lastKnownLocation.timestamp' && value !== '' && value !== undefined && value !== null) {
//           value = new Date(value).toLocaleString('en-US', {
//             weekday: 'short',
//             year: 'numeric',
//             month: 'short',
//             day: '2-digit',
//             hour: 'numeric',
//             minute: 'numeric',
//             hour12: true
//           });
//         }
//         return value;
//       });
//     });
    
//     const doc = new jsPDF();
//     doc.autoTable({
//       head: [columns.map(col => col.header)],
//       body: rows,
//       headStyles: { 
//         fillColor: [181, 22, 22], // Red color
//         textColor: [255, 255, 255] // White text color
//       }
//     });
//     doc.save(`Events-${moment().format('YYYY-MM-DD-HH-mm-ss')}.pdf`);
// };

const downloadReport = () => {
  setLoading(true);
  const elementToCapture = document.getElementById('full-page-content-events');

  const grid = dropDataGridRef.current.instance;
  const columns = grid.getVisibleColumns().filter(col => col.dataField).map(col => ({ header: col.caption, dataField: col.dataField }));
  const rows = grid.getVisibleRows().map(row => {
    return columns.map(col => {
      const nestedKeys = col.dataField.split('.');
      let value = row.data;
      nestedKeys.forEach(key => {
        if (value && typeof value === 'object') {
          value = value[key];
        } else {
          value = undefined;
        }
      });
      if (col.dataField === 'lastKnownLocation.timestamp' && value !== '' && value !== undefined && value !== null) {
        value = new Date(value).toLocaleString('en-US', {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        });
      }
      return value;
    });
  });

  domtoimage.toPng(elementToCapture, { quality: 0.1 })
    .then((dataUrl) => {
      const pdf = new jsPDF();

      // Add the captured image as the first page in the PDF
      pdf.addImage(dataUrl, 'PNG', 10, 10, 190, 120); // Adjust the position and size as needed

      // Add the grid data as subsequent pages using autoTable
      pdf.autoTable({
        head: [columns.map(col => col.header)],
        body: rows,
        headStyles: {
          fillColor: [181, 22, 22], // Red color
          textColor: [255, 255, 255] // White text color
        },
        startY: 130 // Adjust the start position if needed
      });

      // Save the combined PDF with a specified filename
      pdf.save(`Events-${moment().format('YYYY-MM-DD-HH-mm-ss')}.pdf`);
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      console.error('Error capturing element or generating PDF:', error);
    });
  };

  const customizeEventsLabel = (arg) => {
    return {
      text: `${arg.valueText}`,
    };
  }

  async function getData(applyD, yes) {
    // let fromDate = await moment(state2[0].startDate).utc().format();
    // let toDate = await moment(state2[0].endDate).utc().format();
    const fromDate = moment(localStorage.getItem(`${tenant}dateFilterFrom`)).utc().format();
    const toDate = moment(localStorage.getItem(`${tenant}dateFilterTo`)).utc().format();
    if (fromDate === "Invalid date" || toDate === "Invalid date") return true;
    if (yes === true) {
      await OverviewService.getEvents(fromDate, toDate, timeZone).then((value) => {
        if(value.data === null) return;
        if (value?.data?.data?.eventData.length === 0) setLoadingText("No Data")
        else setLoadingText("Loading events...")
        setData(value.data.data.eventData);
        customizeBarData(value.data.data.eventData, fromDate, toDate)
      })
    } else {
      setData([])
      setEvents([])
    }
  }

  const customizeBarData = async (eventListing, fromDate, toDate) => {
    let start_time = moment(fromDate).startOf('day');
    let end_time = moment(toDate).endOf('day');
    let arr = [];
    let calendar = false;
    if (selectedValue === 'Last 24 hours') {
      start_time = moment().subtract(24, 'hours');
      end_time = moment();
    }
    // when its a same day
    // let isSameDay = false;

    // when nothing is selected

    const maxBars = 25

    let hours = end_time.diff(start_time, 'days');
    hours = hours===0?1:(hours==1 && calendar==true ? 2 : hours);
    for(let i=0;i<=maxBars;i++){
      arr.push(moment(start_time).add(i*hours, 'hours').startOf('hour').format("YYYY-MM-DD HH:mm:ss"));
    }

    const listkeys = eventListing
    let data=[];
    let alarmCount = 0, warningCount = 0,informationCount = 0, criticalCount = 0

    for(let i=0;i<arr.length;i++){

        let all_find_time = listkeys.filter(element => (moment(element.time) >= moment(arr[i])) && (moment(element.time) <= moment(arr[i]).add((hours * 60) - 1, 'minutes') ))

        if(all_find_time.length > 0) {
            let criticals = all_find_time.filter(element => element.severity === 'Critical').length;
            let alarms = all_find_time.filter(element => element.severity === 'Alarm').length;
            let warnings = all_find_time.filter(element => element.severity === 'Warning').length;
            let informations = all_find_time.filter(element => element.severity === 'Information').length;

            alarmCount += parseInt(alarms) || 0
            criticalCount += parseInt(criticals) || 0
            warningCount += parseInt(warnings) || 0
            informationCount += informations || 0

            data.push({
                time: moment(arr[i]).format('yyyy-MM-DD HH:mm'),
                critical: criticals,
                alarm: alarms,
                warning: warnings,
                information: informations,
            });
        } else {
            data.push({
              time: moment(arr[i]).format('yyyy-MM-DD HH:mm'), //'02:00<br/> 23 jun 2023'
              critical: 0,
              alarm: 0,
              warning: 0,
              information: 0,
            })
        }
    }
    setEvents(data);
    setEventAlarm(alarmCount)
    setEventCrictical(criticalCount)
    setEventInformation(informationCount)
    setEventWarning(warningCount)
  }


  function computeValType(rowData) {
    return rowData.subtype;
  }

  function handleEntityName(rowData){
    return `${rowData.entityname} ${rowData.name}`
  }


  useEffect(() => {
    if (startDate && endDate) {
      getData(null, true);
    }
}, [startDate, endDate]);
  

  useEffect(() => {
    const periodVal = localStorage.getItem(`${tenant}dateFilterText`);
    if (periodVal !== null && periodVal !== 'null') {
      console.log('period val: ', periodVal);
      if (periodVal === 'Custom') {
          const startDate = moment(localStorage.getItem(`${tenant}dateFilterFrom`));
          const endDate = moment(localStorage.getItem(`${tenant}dateFilterTo`));
          setStartDate(startDate);
          setEndDate(endDate);
      }
      // setSelectedValue(periodVal);
      handleValueChange(periodVal);
    } else {
      // setSelectedValue('Today');
      handleValueChange('Last 24 hours')
    }
    getData(null, true);
}, []);


  const handleStartDate = () => {
    dateBoxRefStartEvent.current.instance.open()
    setSelectedValue("Custom")
  }
  
  const handleEndDate = () => {
    dateBoxRefEndEvent.current.instance.open()
    setSelectedValue("Custom")
  }

  const handleStartDateChange = async (value) => {
    await setStartDate(value);
    if (value && endDate && moment(value).isAfter(endDate)) {
      await setEndDate(moment(value).add(1, 'days').toDate());
      localStorage.setItem(`${tenant}dateFilterTo`, moment(value).add(1, 'days').toDate())
    }
    localStorage.setItem(`${tenant}dateFilterFrom`, moment(value).format())
    // getAnchors()

    if (endDate) {
      localStorage.setItem(`${tenant}dateFilterTo`, moment(endDate).format());
    }
    getData(null, true);
  };

  const handleEndDateChange = async (value) => {
    await setEndDate(value);
    if (value && startDate && moment(value).isBefore(startDate)) {
      await setStartDate(moment(value).subtract(1, 'days').toDate());
      localStorage.setItem(`${tenant}dateFilterFrom`, moment(value).subtract(1, 'days').toDate())
    }
    localStorage.setItem(`${tenant}dateFilterTo`, moment(value).format())
    // getAnchors()
    if (startDate) {
      localStorage.setItem(`${tenant}dateFilterFrom`, moment(startDate).format());
    }
    getData(null, true);
  };

  async function onLegendClick (e) {
    if(e.target._visible) {
      setEventFilters([...eventFilters, e.target.name])
    } else {
      let list = eventFilters
      list.splice(list.indexOf(e.target.name), 1)
      setEventFilters(list);
      if(eventFilters.length==0){
        setFilterData(data)
      } else{
        const results = data.filter(item=> !list.includes(item.severity))
        setFilterData(results)
      }
    }
    e.target._visible ? e.target.hide() : e.target.show()
  }

  const handleValueChange = async (value) => {
    // await resetData();
    setSelectedValue(value);
    if (value === 'Custom') {
      setIsDisabled(false)
      localStorage.setItem(`${tenant}dateFilterText`, value);
    } else {
      // setManageDateBox(true);
      setStartDate(null);
      setEndDate(null);
      let start, end;
      switch (value) {
        case 'Last 24 hours':
          start = moment().subtract(24, 'hours').format();
          end = moment().format();
          break;
        case 'Today':
          start = moment().startOf('day').format();
          end = moment().endOf('day').format();
          break;
        case 'Last 5 min':
          start = moment().subtract(5, 'minutes').format();
          end = moment().format();
          break;
        case 'Last 20 min':
          start = moment().subtract(20, 'minutes').format();
          end = moment().format();
          break;
        case 'Last 1 hour':
          start = moment().subtract(1, 'hour').format();
          end = moment().format();
          break;
        case 'Last 4 hours':
          start = moment().subtract(4, 'hours').format();
          end = moment().format();
          break;
        case 'Last 12 hours':
          start = moment().subtract(12, 'hours').format();
          end = moment().format();
          // if (moment(end).diff(start, 'hours') <= 12) {
          //   end = moment().endOf('hour').format(); // Set end to end of current hour
          // }
          break;
        case 'Yesterday':
          start = moment().subtract(1, 'day').startOf('day').format();
          end = moment().subtract(1, 'day').endOf('day').format();
          break;
        case 'This Week':
          start = moment().startOf('week').format();
          end = moment().endOf('week').format();
          break;
        case 'Last Week':
          start = moment().subtract(1, 'weeks').startOf('week').format();
          end = moment().subtract(1, 'weeks').endOf('week').format();
          break;
        case 'This Month':
          start = moment().startOf('month').format();
          end = moment().endOf('month').format();
          break;
        case 'Last Month':
          start = moment().subtract(1, 'month').startOf('month').format();
          end = moment().subtract(1, 'month').endOf('month').format();
          break;
        // case 'Last 1 day':
        //   start = moment().subtract(1, 'day').format();
        //   end = moment().format();
        //   break;
        case 'Last 7 days':
          start = moment().subtract(7, 'days').format();
          end = moment().format();
          break;
        default:
          break;
      }
      console.log('start: ', start, ',end: ', end);
      setStartDate(start);
      setEndDate(end);
      // localStorage.setItem(tenant + "dateFilterText", `${value}`)
      // localStorage.setItem(tenant + "dateFilterFrom", `${start}`)
      // localStorage.setItem(tenant + "dateFilterTo", `${end}`)
      localStorage.setItem(`${tenant}dateFilterText`, value);
    }
  };

  // function clearSelection() {
  //   localStorage.setItem(tenant + "dateFilterFrom", null)
  //   localStorage.setItem(tenant + "dateFilterTo", null)
  //   localStorage.setItem(tenant + "dateFilterText", `Last 24 hours`)
  //   // resetData();
  //   window.location.reload();
  // }

  useEffect(() => {
    const results = data.filter(item=> !eventFilters.includes(item.severity))
    setFilterData(results)
  }, [eventFilters, data]);

  return (
  <>
  <div className="page-content" style={{overflowY: "scroll"}} >
    <div id="full-page-content-events" className='eventscontainer' ref={downloadTargetRefEvents}>
		<div className='pageFullWidthEvents'>
			<h5 className='marginZeroEvents' style={{marginBottom: "15px"}}><b>Events</b></h5>
      <section className='Events_text_wrapper'>
      <p className='eventsDesc'>In the selected date range there were <b>{eventWarning} warning(s)</b>, <b>{eventAlarm} alarm(s)</b>, <b>{eventCrictical} critical events(s)</b> and <b>{eventInformation} information event(s)</b>.</p>
      <div className='uploadBtnAssetsReport' style={{right: "295px", top: "20px"}}>
        <button id="generatePdfButton" alt="Download Report" title="Download Report" type="button" onClick={() => downloadReport('events')}>
          <img src="../icons/reports.svg" alt="Download Report" />
        </button>
      </div>
      </section>
        <div className='btnDateContainerEvents'>
        <SelectBox
              style={{ marginRight: '10px', width: '20%' }}
              dataSource={['Today', 'Last 5 min', 'Last 20 min', 'Last 1 hour', 'Last 4 hours', 'Last 12 hours', 'Last 24 hours', 'Yesterday', 'This Week', 'Last Week', 'This Month', 'Last Month', 'Last 7 days', 'Custom']}
              value={selectedValue}
              // onValueChanged={handleValueChange}
              onValueChanged={(e) => handleValueChange(e.value)}
              labelMode="static"
              label="Period"
              stylingMode="outlined"
            />
            <div onClick={() => handleStartDate()}>
              <DateBox
                ref={dateBoxRefStartEvent}
                style={{ marginRight: '10px'}}
                type="datetime"
                value={startDate}
                onValueChanged={async (e) => await handleStartDateChange(e.value)}
                placeholder="Start Date"
                labelMode="static"
                label="Start Date"
                stylingMode="outlined"
                displayFormat="yyyy-MM-dd HH:mm"
                valueFormat="yyyy-MM-dd HH:mm"
              />
            </div>
            <div onClick={() => handleEndDate()}>
              <DateBox
                ref={dateBoxRefEndEvent}
                style={{ marginRight: '10px'}}
                type="datetime"
                value={endDate}
                onValueChanged={async (e) => await handleEndDateChange(e.value)}
                placeholder="End Date"
                labelMode="static"
                label="End Date"
                stylingMode="outlined"
                displayFormat="yyyy-MM-dd HH:mm"
                valueFormat="yyyy-MM-dd HH:mm"
              />
            </div>

        </div>
      {/* </Popup> */}
					<div className='analyticsContainerEvents'>
						<div className='eventAnalyticsPage'>
							<Chart
                id="eventChart"
                dataSource={events}
                onLegendClick={onLegendClick}
                >
                  <CommonSeriesSettings
                    argumentField="time"
                    type="stackedBar"
                  />
                  <ChartSeries
                    valueField="critical"
                    name="Critical"
                    color="#C41C1D"
                  />
                  <ChartSeries
                    valueField="alarm"
                    name="Alarm"
                    color="#FFC001"
                  />
                  <ChartSeries
                    valueField="warning"
                    name="Warning"
                    color="#6D6D6D"
                  />
                  <ChartSeries
                    valueField="information"
                    name="Information"
                    color="#CCCCCC"
                  />
                  <ChartLegend
                    // customizeItems={sortLegendItems}
                    style={{cursor:"pointer"}}
                    verticalAlignment="left"
                    horizontalAlignment="left"
                    itemTextPosition="left"
                  />
                  <Export enabled={false} />
                  <Tooltip
                    enabled={true}
                    horizontalAlignment="left"
                    customizeTooltip={customizeEventsLabel}
                  />
                </Chart>
						</div>
					</div>
				</div>
      </div>
      <DataGrid
        id="events"
        loadPanel={false}
        noDataText={loadingText}
        keyExpr="id"
        showColumnLines={true}
        showRowLines={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnMinWidth={20}
        columnAutoWidth={true}
        showBorders={true}
        ref={dropDataGridRef}
        // onRowInserting={onRowInserting}
        // onRowUpdating={onRowUpdating}
        onColumnsChanging={function (e) { if(e.component?.getDataSource()?._totalCount <= 0 && data.length > 0){
          setLoadingText('No Data'); }
        }}
        dataSource={eventFilters.length>0 ? filterData : data}>
        <Paging enabled={false} key={'paging'}/>
        <Column
          allowSorting={true}
          dataField={DOMPurify.sanitize('time')}
          caption={"Date"}
          minWidth={100}
          width={220}
          dataType="datetime"
          format="yyyy-MM-dd HH:mm"
          key={'time'}
          fixed={true}
          defaultSortOrder="desc"
        >
        </Column>
        <Column
          allowSorting={true}
          dataField={DOMPurify.sanitize('name')}
          caption={'Entity'}
          visible={true}
          dataType='string'
          key={'name'}
          fixed={true}
          minWidth={50}
          width={120}
          calculateDisplayValue={handleEntityName}
        >
        </Column>
        <Column
          allowSorting={true}
          dataField={DOMPurify.sanitize('type')}
          caption={'Type'}
          dataType='string'
          visible={true}
          key={'type'}
          fixed={true}
          minWidth={30}
          width={200}
          calculateDisplayValue={computeValType}
        ></Column>
        <Column
          allowSorting={true}
          dataField={DOMPurify.sanitize('severity')}
          showInColumnChooser={false}
          caption={'Severity'}
          dataType="string"
          visible={true}
          minWidth={10}
          width={120}
          key={'severity'}
          fixed={true}
        ></Column>
        <Column
          allowSorting={true}
          dataField={DOMPurify.sanitize('description')}
          caption={'Description'}
          key={'description'}
          fixed={true}
          minWidth={50}
          visible={true}
        >
        </Column>
      </DataGrid>
      <LoadingPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={loading}
        showIndicator={true}
        showPane={true}
        shading={true}
        closeOnOutsideClick={false}
      />
      <Toast
        setParentToastConfig={setToastConfig}
        toastBody={toastConfig}
      />
      </div>
    </>
  );
}
